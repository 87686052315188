var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"button",class:[
        _vm.color && `button-${_vm.color}`,
        _vm.size && `button-${_vm.size}`,
        _vm.loading && 'is-loading',
        _vm.wide && 'button-block',
        _vm.wideMobile && 'button-wide-mobile'
    ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }