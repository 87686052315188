<template>
  <v-app id="keep">
    <AppBar v-if="!isDiapo" />
    <component :is="setLayout" :key="setLayout.name">
      <!-- <v-main class="fill-height">
        <vue-progress-bar></vue-progress-bar>

        <router-view :key="$route.fullPath" />
      </v-main> -->
    </component>
    <v-snackbar :value="get_snack.display" :color="get_snack.color" :bottom="'bottom'" multi-line="multi-line">
      {{ get_snack.text }}
      <template v-slot:action="{}">
        <v-btn text @click="$store.dispatch('set_snack', { ...get_snack, display: false })">Close</v-btn>
      </template>
    </v-snackbar>
    <cookie-law theme="base">
      <v-row no-gutters align-center justify="space-around" slot-scope="props">
        <v-col class="px-4" align-self="center">Ce site utilise des cookies pour améliorer votre expérience 🍪</v-col>
        <v-col class="text-right">
          <v-btn large color="green" class="white--text" @click="props.accept">J'accepte</v-btn>
        </v-col>
      </v-row>
    </cookie-law>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import vueProgressBar from "vue-progressbar";

import AppBar from "@/components/Navigation/AppBar";

import CLayout from "@/components/cruip_template/layouts/LayoutDefault.vue";

import { ScrollReveal } from "@/utils/mixins/ScrollReveal.js";

import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    // "progress-bar": vueProgressBar,
    CLayout,
    AppBar,
    CookieLaw,
  },

  mixins: [ScrollReveal],

  data: () => ({
    drawer: null,
    items: [
      { icon: "mdi-clipboard-file-outline", text: "Notes" },
      { icon: "mdi-reminder", text: "Reminders" },
      { divider: true },
      { heading: "Labels" },
    ],
    setLayout: "div",
    // layout: "div"
  }),

  async mounted() {
    if (this.$cookies.get("connect.sid")) {
      let { data } = await this.$axios.get("/whoami");

      this.set_user({ ...this.get_user, ...data });
    }
  },

  methods: {
    ...mapActions(["set_user"]),

    async logout() {
      try {
        await this.$axios.post("/logout");
      } catch (err) {
        alert("Couldn't logout", err);
      } finally {
        this.set_user({});
        // this.$cookies.remove("connect.sid");
        this.$router.push("/login");
      }
    },

    updateLayout() {
      if (this.$route.meta.layout)
        this.setLayout =
          typeof this.$route.meta.layout === "function" ? this.$route.meta.layout(this.get_user) : this.$route.meta.layout;
      else this.setLayout = CLayout;
    },
  },
  computed: {
    ...mapGetters(["get_user", "get_snack"]),

    shouldRemovePadding() {
      this.$route.name;
      if (this.$route.name === "diapo") return true;
      else if (this.$route.name === "home") return true;
      else if (this.$route.name === "login") return true;
      return false;
    },

    shouldAddPadding() {
      this.$route.name;
      this.get_user.username;

      if (!this.get_user.username && this.$route.path === "/") return false;
      else if (this.$route.path === "/login") return false;
      else if (this.$route.name === "diapo") return false;
      else if (this.$route.name === "about") return false;
      return true;
    },

    isDiapo() {
      this.$router;
      return this.$route.path == "/diapo";
    },
  },

  watch: {
    // call again the method if the route changes
    $route: {
      handler: "updateLayout",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Roboto", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.grow {
  transition: all 0.1s ease-in-out;
}

.grow:hover {
  transform: scale(1.15);
}

.logo {
  cursor: pointer;
}

.no-padding {
  padding-top: 0px !important;
}

.add-padding {
  padding-top: 64px !important;
}
</style>
