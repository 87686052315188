import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import VueProgressBar from 'vue-progressbar'

import Fragment from 'vue-fragment';
Vue.use(Fragment.Plugin);

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import '@/styles/css/main.css';
import "@/styles/scss/style.scss";

Vue.config.productionTip = false

Vue.component('VueElementLoading', VueElementLoading)

Vue.use(VueProgressBar, {
  color: '#73ff66',
  failedColor: '#874b4b'
});

Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  console.error(err);
  console.log(vm, info);
  // TODO
}


console.log(process.env.NODE_ENV);


export default new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')