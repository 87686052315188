var timeout = null;

export const set_snack = ({ commit }, snack) => {
    commit('SET_SNACK', snack);
    if (snack.display === true) {
        if (timeout != null)
            clearTimeout(timeout);
        timeout = setTimeout(() => {
            commit('SET_SNACK', { ...snack, display: false });
        }, 6000); // 6 seconds is the default v-snackbar timeout : https://vuetifyjs.com/en/components/snackbars
    } else if (timeout != null) {
        clearTimeout(timeout);
    }
}