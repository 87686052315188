var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[(_vm.label)?_c('c-label',{attrs:{"label":_vm.label,"id":_vm.id,"labelHidden":_vm.labelHidden}}):_vm._e(),_c('div',{class:_vm.wrapperClasses},[((_vm.type)==='checkbox'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],staticClass:"form-input",class:[
					_vm.size && `form-input-${_vm.size}`,
					_vm.status && `form-${_vm.status}`
				],attrs:{"name":_vm.name,"id":_vm.id,"disabled":_vm.disabled,"required":_vm.required,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.computedValue)?_vm._i(_vm.computedValue,null)>-1:(_vm.computedValue)},on:{"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus,"change":function($event){var $$a=_vm.computedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedValue=$$c}}}}):((_vm.type)==='radio'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],staticClass:"form-input",class:[
					_vm.size && `form-input-${_vm.size}`,
					_vm.status && `form-${_vm.status}`
				],attrs:{"name":_vm.name,"id":_vm.id,"disabled":_vm.disabled,"required":_vm.required,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.computedValue,null)},on:{"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus,"change":function($event){_vm.computedValue=null}}}):(_vm.type !== 'textarea')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],staticClass:"form-input",class:[
					_vm.size && `form-input-${_vm.size}`,
					_vm.status && `form-${_vm.status}`
				],attrs:{"name":_vm.name,"id":_vm.id,"disabled":_vm.disabled,"required":_vm.required,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.computedValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.computedValue=$event.target.value},_vm.onInput],"blur":_vm.onBlur,"focus":_vm.onFocus}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],staticClass:"form-input",class:[
					_vm.size && `form-select-${_vm.size}`,
					_vm.type && `form-${_vm.type}`
				],attrs:{"name":_vm.name,"id":_vm.id,"disabled":_vm.disabled,"required":_vm.required,"placeholder":_vm.placeholder,"rows":_vm.rows},domProps:{"value":(_vm.computedValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.computedValue=$event.target.value},_vm.onInput],"blur":_vm.onBlur,"focus":_vm.onFocus}}),_vm._t("default")],2),(_vm.hint)?_c('c-hint',{attrs:{"status":_vm.status,"hint":_vm.hint}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }