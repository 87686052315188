import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Confidentialite from '../views/Modalite/Confidentialite.vue'
import CGU from '../views/Modalite/CGU.vue'

import store from '../store/index'


//
import CLayout from "@/components/cruip_template/layouts/LayoutDefault.vue";
import CLayoutDiapo from "@/components/cruip_template/layouts/LayoutDiapo.vue";
import CLayoutNoFooter from "@/components/cruip_template/layouts/LayoutNoFooter.vue";
//

Vue.use(VueRouter)
Vue.use(require('vue-cookies'));

export const routes = [
  {
    path: '/',
    name: "home",
    component: Home,
    meta: {
      layout: (user) => {
        return user.username ? CLayoutNoFooter : CLayout
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: CLayout
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      layout: CLayoutDiapo
    }
  },
  {
    path: '/company/:type?',
    name: 'company.concession',
    component: () => import('../views/Company.vue'),
    meta: { requiresAuth: true, layout: CLayoutNoFooter }
  },
  {
    path: '/options_diapo',
    name: 'options diapo',
    component: () => import('../views/Diapo/Options.vue'),
    meta: { requiresAuth: true, layout: CLayoutNoFooter }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: { layout: CLayout }
  },
  {
    path: '/essai-gratuit',
    name: 'free trial',
    component: () => import('../views/EssaiGratuit.vue'),
    meta: { layout: CLayout }
  },
  {
    path: '/diapo',
    name: 'diapo',
    component: () => import('../views/Diapo/Diapo.vue'),
    meta: { requiresAuth: true, layout: CLayoutDiapo }
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/Index.vue'),
    children: [
      {
        path: '', component: () => import('@/views/admin/Users.vue'), name: 'admin',
        meta: { requiresAuth: true, roles: ["admin"], layout: CLayoutNoFooter },
      }, // point /admin to /admin/users component
      {
        path: '/users', component: () => import('@/views/admin/Users.vue'), name: "admin.users",
        meta: { requiresAuth: true, roles: ["admin"], layout: CLayoutNoFooter },
      },
      {
        path: '/companies/:name?', component: () => import('@/views/admin/Company.vue'), name: "admin.companies",
        meta: { requiresAuth: true, roles: ["admin"], layout: CLayoutNoFooter },
      },
      {
        path: '/vehicule_providers/:user?', component: () => import('@/views/admin/Cardiff.vue'), name: "admin.VehiculeProviders",
        meta: { requiresAuth: true, roles: ["admin"], layout: CLayoutNoFooter },
      },

      // ...autres sous routes
    ]
    // name: 'manage_location',
    // component: () => import('../views/ManageLocation.vue'),
    // meta: { requiresAuth: true, roles: ["admin"], layout: CLayoutNoFooter }
  },
  {
    path: '/confidentialite',
    name: 'confidentialite',
    component: Confidentialite,
    meta: {
      layout: CLayout
    }
  },
  {
    path: '/CGU',
    name: 'CGU',
    component: CGU,
    meta: {
      layout: CLayout
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/About.vue'),
  //   meta: { requiresAuth: true, layout: CLayoutDiapo }
  // },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  routes
})

function checkUserRoles(route) {
  return route.meta.roles && store.state.moduleUser.user.roles ? store.state.moduleUser.user.roles.some(r => route.meta.roles.indexOf(r) >= 0) : false
}
function checkUserAuthenticated() {
  return store.state.moduleUser.user.username ? true : false

}

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (checkUserAuthenticated()) {

      if (to.matched.some(record => record.meta.roles)) {
        if (checkUserRoles(to)) {
          next();
        }
        else {
          next({
            path: '/logout'
          })
        }
      }
      next();
    } else {
      next({
        path: '/logout'
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})



export default router
