<template>
  <section
    class="cta section center-content-mobile reveal-from-bottom"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
    ]"
  >
    <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
      <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
    </vue-element-loading>
    <form class="container" v-on:submit.prevent="submit" id="CTA">
      <div
        class="cta-inner section-inner"
        :class="[topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider', split && 'cta-split']"
      >
        <div class="cta-slogan mb-0">
          <div class="body-2">Prenez le contrôle de vos écrans</div>
          <div class="h3 mt-0">Essayer par vous-même, c'est gratuit</div>
        </div>
        <div class="cta-action d-block" style="min-width: 280px">
          <v-text-field
            id="newsletter"
            type="email"
            label="Subscribe"
            background-color="#e8e8e8"
            light
            dense
            solo
            name="email"
            placeholder="Votre email"
            append-outer-icon="mdi-send"
            hide-details
            v-model="email"
            @click:append-outer="submit"
            v-on:keyup.enter="submit"
          ></v-text-field>
          <!-- <c-input
            id="newsletter"
            type="email"
            label="Subscribe"
            label-hidden
            has-icon="right"
            name="email"
            placeholder="Votre email"
            v-model="email"
            v-on:keyup.enter="submit"
          >
            <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
            </svg>
          </c-input> -->
          <div class="text-right caption font-italic">Aucune carte de crédit requise</div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { SectionProps } from "@/utils/SectionProps.js";
import CInput from "@/components/cruip_template/elements/Input.vue";
import snackBar from "@/utils/mixins/snackBar";
import { SemipolarSpinner } from "epic-spinners";

export default {
  name: "CCta",
  components: {
    CInput,
    SemipolarSpinner,
  },

  mixins: [SectionProps, snackBar],

  props: {
    split: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      email: "",
    };
  },

  methods: {
    async submit() {
      if (this.loading == false) {
        if (
          this.email ||
          !/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(
            this.email
          )
        ) {
          this.loading = true;
          try {
            await this.$axios.post("free-trial", { email: this.email });
            this.displaySuccessSnack(
              `Vous receverez les accèss de votre compte d'essai dans votre boîte mail dans quelques minutes`
            );
          } catch (error) {
            console.error(error);
            this.displayErrorSnack(
              `Une erreur s'est produite lors de la création de votre compte d'essai. Contactez s'il vous plaît notre équipe afin d'obtenir votre essai gratuit.`
            );
          } finally {
            this.loading = false;
          }
        } else {
          this.displayErrorSnack(`Le format de l'email que vous avez renseigné n'est pas valide`);
        }
      }
    },
  },
};
</script>
