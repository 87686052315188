<template>
  <section
    class="pricing section"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
    ]"
  >
    <div class="container">
      <div class="pricing-inner section-inner" :class="[topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider']">
        <c-section-header :data="sectionHeader" tag="h1" class="center-content reveal-from-bottom mb-1" />

        <!-- :class="{ 'two-column': $vuetify.breakpoint.smAndUp }" -->
        <ul class="mb-12 pricing-item-features-list " style="align-self: center">
          <li class="is-checked fixtur reveal-from-left">Vos VO</li>
          <li class="is-checked fixtur reveal-from-right">Vos VN</li>
          <li class="is-checked fixtur reveal-from-left">Vos VD</li>
          <li class="is-checked fixtur reveal-from-right">Vidéos constructeur</li>
          <li class="is-checked fixtur reveal-from-left">Vidéos du groupe</li>
          <li class="is-checked fixtur reveal-from-right">Vidéos du site</li>
          <li class="is-checked fixtur reveal-from-left">Opération service après vente</li>
          <li class="is-checked fixtur reveal-from-right">Info traffic en temps réel</li>
          <li class="is-checked fixtur reveal-from-left">Météo nationale, régionale et locale</li>
          <li class="is-checked fixtur reveal-from-right">
            Les dernières informations sportives et économiques nationale et locale
          </li>
        </ul>

        <!-- <div v-if="pricingSwitcher" class="pricing-switcher center-content reveal-from-top">
          <c-switch v-model="priceChangerValue" true-value="1" false-value="0" right-label="annuelle">
            mensuelle
          </c-switch>
        </div>
        <div v-if="pricingSlider" class="pricing-slider center-content reveal-from-top">
          <label class="form-slider">
            <span class="mb-16">Combien avez-vous de véhicule par mois en annonce ?</span>
            <input type="range" ref="slider" v-model="priceChangerValue" @input="handlePricingSlide" />
          </label>
          <div ref="sliderValue" class="pricing-slider-value">
            {{ getPricingData(priceInput) }}
          </div>
        </div> -->
        <!-- <div class="tiles-wrap" :class="[pushLeft && 'push-left']">
          <div class="tiles-item reveal-from-right" data-reveal-delay="400">
            <div class="tiles-item-inner">
              <div class="pricing-item-content">
                <div class="pb-24 mb-3">
                  <div class="pricing-item-price mb-4">
                    <span class="pricing-item-price-amount h1">{{ getPricingData(this.priceOutput.plan1, 1) }}</span>
                    <span class="pricing-item-price-currency h3">{{ getPricingData(this.priceOutput.plan1, 0) }}</span>
                    <span class="pricing-item-price-after text-sm">{{ getPricingData(this.priceOutput.plan1, 2) }}</span>
                  </div>
                  <div class="text-xs text-color-low">
                    Plan basique
                  </div>
                </div>
                <div class="pricing-item-features mb-40">
                  <ul class="pricing-item-features-list list-reset text-xs mb-32">
                    <li class="is-checked">Mise à jour quotidienne des photos</li>
                    <li class="is-checked">Jusqu'à 5 publicités images ou video</li>
                    <li class="is-checked">Logo de l'entreprise sur les annonces</li>
                    <li>Météo en temps réel</li>
                    <li>État du trafic routier local en temps réel</li>
                    <li>Informations sportives et économiques</li>
                  </ul>
                </div>
              </div>
              <div class="pricing-item-cta mb-8">
                <c-button tag="a" color="primary" wide href="#">Start free trial</c-button>
              </div>
            </div>
          </div>

          <div class="tiles-item reveal-from-bottom" data-reveal-delay="200">
            <div class="tiles-item-inner">
              <div class="pricing-item-content">
                <div class="pb-24 mb-3">
                  <div class="pricing-item-price mb-4">
                    <span class="pricing-item-price-amount h1">{{ getPricingData(this.priceOutput.plan2, 1) }}</span>
                    <span class="pricing-item-price-currency h3">{{ getPricingData(this.priceOutput.plan2, 0) }}</span>
                    <span class="pricing-item-price-after text-sm">{{ getPricingData(this.priceOutput.plan2, 2) }}</span>
                  </div>
                  <div class="text-xs text-color-low">
                    Plan professionel
                  </div>
                </div>
                <div class="pricing-item-features mb-40">
                  <ul class="pricing-item-features-list list-reset text-xs mb-32">
                    <li class="is-checked">Mise à jour biquotidienne des photos</li>
                    <li class="is-checked">Jusqu'à 7 publicités images ou video</li>
                    <li class="is-checked">Logo de l'entreprise sur les annonces</li>
                    <li class="is-checked">Météo en temps réel</li>
                    <li>État du trafic routier local en temps réel</li>
                    <li>Informations sportives et économiques</li>
                  </ul>
                </div>
              </div>
              <div class="pricing-item-cta mb-8">
                <c-button tag="a" color="primary" wide href="#">Start free trial</c-button>
              </div>
            </div>
          </div>

          <div class="tiles-item reveal-from-left" data-reveal-delay="400">
            <div class="tiles-item-inner">
              <div class="pricing-item-content">
                <div class="pb-24 mb-3">
                  <div class="pricing-item-price mb-4">
                    <span class="pricing-item-price-amount h1">{{ getPricingData(this.priceOutput.plan3, 1) }}</span>
                    <span class="pricing-item-price-currency h3">{{ getPricingData(this.priceOutput.plan3, 0) }}</span>
                    <span class="pricing-item-price-after text-sm">{{ getPricingData(this.priceOutput.plan3, 2) }}</span>
                  </div>
                  <div class="text-xs text-color-low">
                    Plan business
                  </div>
                </div>
                <div class="pricing-item-features mb-40">
                  <ul class="pricing-item-features-list list-reset text-xs mb-32">
                    <li class="is-checked">Mise à jour biquotidienne des photos</li>
                    <li class="is-checked">Jusqu'à 7 publicités images ou video</li>
                    <li class="is-checked">Logo de l'entreprise sur les annonces</li>
                    <li class="is-checked">Météo en temps réel</li>
                    <li class="is-checked">État du trafic routier local en temps réel</li>
                    <li class="is-checked">Informations sportives et économiques</li>
                  </ul>
                </div>
              </div>
              <div class="pricing-item-cta mb-8">
                <c-button tag="a" color="primary" wide href="#">Start free trial</c-button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { SectionTilesProps } from "@/utils/SectionProps.js";
import CSectionHeader from "@/components/cruip_template/sections/partials/SectionHeader.vue";
import CSwitch from "@/components/cruip_template/elements/Switch.vue";
import CButton from "@/components/cruip_template/elements/Button.vue";

export default {
  name: "CPricing",
  components: {
    CSectionHeader,
    CSwitch,
    CButton,
  },
  mixins: [SectionTilesProps],
  props: {
    pricingSwitcher: {
      type: Boolean,
      default: false,
    },
    pricingSlider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sectionHeader: {
        title: `Des offres sur mesure adaptées à votre établissement`,
        paragraph: "Personnaliser votre écran selon votre besoin en diffusant :",
      },
      priceChangerValue: "0",
      priceInput: {
        0: "100",
      },
      priceOutput: {
        plan1: {
          0: ["€", "0", "/ mois / écran"],
        },
        plan2: {
          0: ["€", "0", "/ mois / écran"],
        },
        plan3: {
          0: ["€", "0", "/ mois / écran"],
        },
      },
    };
  },
  methods: {
    // handlePricingSlide(e) {
    //   this.handleSliderValuePosition(e.target);
    // },
    // handleSliderValuePosition(input) {
    //   const multiplier = input.value / input.max;
    //   const thumbOffset = this.thumbSize * multiplier;
    //   const priceInputOffset = (this.thumbSize - this.$refs.sliderValue.clientWidth) / 2;
    //   this.$refs.sliderValue.style.left = input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
    // },
    // getPricingData(values, set) {
    //   return set !== undefined ? values[this.priceChangerValue][set] : values[this.priceChangerValue];
    // },
  },
  mounted() {
    // if (this.pricingSlider) {
    //   for (let i = 0; i <= 15; i++) {
    //     this.priceInput[i] = 30 + i * 30;
    //     this.priceOutput.plan1[i] = ["€", Math.ceil(27 + i * 2), "/ mois / écran"];
    //     this.priceOutput.plan2[i] = ["€", Math.ceil(35 + i * 2.4), "/ mois / écran"];
    //     this.priceOutput.plan3[i] = ["€", Math.ceil(45 + i * 3), "/ mois / écran"];
    //   }
    //   this.$refs.slider.setAttribute("min", 0);
    //   this.$refs.slider.setAttribute("max", Object.keys(this.priceInput).length - 1);
    //   this.thumbSize = parseInt(window.getComputedStyle(this.$refs.sliderValue).getPropertyValue("--thumb-size"), 10);
    //   this.handleSliderValuePosition(this.$refs.slider);
    // }
  },
};
</script>

<style scoped>
.two-column {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.fixtur::after {
  content: "";
  display: block;
  width: 31px !important;
  height: 25px !important;
  margin-right: 21px !important;
  background-repeat: no-repeat;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
</style>
