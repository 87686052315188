<template>
  <section
    class="hero section mb-10 p-relative bg-color"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
    ]"
  >
    <!-- TOP BLACK BAR -->
    <!-- <div class="p-absolute bg-color" style="width: 100%; height: 22%;"></div> -->
    <!-- BOT BLACK BAR -->
    <!-- <div class="p-absolute black" style="width: 100%; height: 5%; bottom: 0%"></div> -->

    <!-- DESKTOP VIDEO -->
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <!-- FILTER -->
      <div class="p-absolute video-filter" style="width: 100%; height:100%; bottom: 0"></div>
      <v-container class="fluid p-absolute fill-height" style="max-width: 100%;">
        <HeroContent />
      </v-container>
      <v-row class="fill-height" style="z-index: 1" no-gutters>
        <iframe
          src="https://player.vimeo.com/video/527979057?background=1"
          :width="videoWidth"
          :height="videoHeight"
          frameborder="0"
          allow="autoplay; loop; fullscreen"
          fullscreen
        >
        </iframe>
      </v-row>
    </div>

    <div v-else>
      <v-container class="fluid fill-height pa-0" style="margin-top: 6rem">
        <v-img :src="require('@/assets/images/landingpage-img.jpg')">
          <div class="p-absolute video-filter" style="width: 100%; height:100%; bottom: 0"></div>

          <v-container class="fluid p-absolute fill-height pa-0"> </v-container>
          <HeroContent />
        </v-img>
      </v-container>
    </div>
  </section>
</template>

<script>
import { SectionSplitProps } from "@/utils/SectionProps.js";
import CButton from "@/components/cruip_template/elements/Button.vue";
import HeroContent from "@/components/cruip_template/sections/partials/HeroSplitContent";

export default {
  name: "CHeroSplit",
  components: {
    CButton,
    HeroContent,
  },
  mixins: [SectionSplitProps],

  data() {
    return {
      tvMode: false,
      tvHeightSize: { small: 14, large: 16 },

      remotePos: { x: 0, y: 0 },

      carouselIndex: 0,

      interval: null,
    };
  },

  mounted() {
    try {
      const { x, y, height } = document.getElementsByClassName("monitor-content")[0].getBoundingClientRect();

      this.remotePos.x = x;
      this.remotePos.y = y + height;
    } catch (error) {}
  },

  beforeDestroy() {
    this.clearInterval();
  },

  methods: {
    mouseover(e) {
      this.remotePos.x = e.pageX;
      this.remotePos.y = e.pageY;
    },

    incrementCarousel() {
      if (this.carouselIndex + 1 > 2) this.carouselIndex = 0;
      else this.carouselIndex++;
    },

    switchTvMode() {
      this.tvMode = !this.tvMode;
      this.carouselIndex = 0;

      if (this.tvMode) this.interval = setInterval(this.incrementCarousel, 8000);
      else if (!this.tvMode) this.clearInterval();
    },

    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },

  computed: {
    videoWidth() {
      this.$vuetify.breakpoint.width;
      return this.$vuetify.breakpoint.width;
    },
    videoHeight() {
      this.$vuetify.breakpoint.height;
      if (this.$vuetify.breakpoint.height < (this.$vuetify.breakpoint.width / 16) * 9)
        return (this.$vuetify.breakpoint.width / 16) * 9;
      return this.$vuetify.breakpoint.height;
    },
  },
};
</script>

<style lang="scss" scoped>
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$screen-background: #121010;

.btn-spacing {
  letter-spacing: 0.06em;
}

.bg-color {
  background-color: #16171b;
}

@media screen and (max-width: 940px) {
  .video-filter {
    background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(4, 4, 4, 0.404) 14%, rgba(1, 1, 1, 0.075) 100%);
  }
}

@media screen and (min-width: 940px) {
  .video-filter {
    background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(4, 4, 4, 0.24831939611782217) 50%, rgba(1, 1, 1, 0.322) 100%);
  }
}
// .video-filter {
//   background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(4, 4, 4, 0.24831939611782217) 50%, rgba(1, 1, 1, 0.322) 100%);
// }

.contour {
  box-shadow: 1px 1px 95px 7px #d0d0d010;
}
</style>
