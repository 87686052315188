<template>
  <!--  template-styles is a class that isolate my template style -->
  <div>
    <div class="neon-cruip">
      <div class="has-animations is-loaded" id="my-body">
        <c-hero-split class="" />
      </div>
    </div>
    <c-complete-features />

    <!-- <c-team></c-team> -->
    <div class="neon-cruip">
      <div class="has-animations is-loaded" id="my-body2">
        <c-pricing pricingSlider />
        <!-- <c-faq /> -->
        <c-cta split />
      </div>
    </div>
  </div>
</template>

<script>
// import layout
// import section header
import CHeroSplit from "@/components/cruip_template/sections/HeroSplit.vue";
import CCompleteFeatures from "@/components/cruip_template/sections/CompleteFeatures.vue";
// import CFeaturesTiles from "@/components/cruip_template/sections/FeaturesTiles.vue";
import CCta from "@/components/cruip_template/sections/Cta.vue";
import CPricing from "@/components/cruip_template/sections/Pricing.vue";
// import CTeam from "@/components/cruip_template/sections/Team.vue";
import CFaq from "@/components/cruip_template/sections/Faq.vue";

// import some required elements
import CImage from "@/components/cruip_template/elements/Image.vue";
import CModal from "@/components/cruip_template/elements/Modal.vue";

export default {
  name: "DisconnectHome",
  components: {
    CHeroSplit,
    CCompleteFeatures,
    // CFeaturesTiles,
    CCta,
    CPricing,
    // CTeam,
    CImage,
    CModal,
    CFaq,
  },

  data() {
    return {
      genericSection01Header: {
        title: "Développé avec des professionnels de la vente d'automobile",
        paragraph: `Notre service a été utilisé dès sa conception dans des concessions automobiles afin d'élaborer un produit le plus adapté à votre secteur.
          Totalement personnalisable, il vous permet de prendre le contrôle total sur ce que diffuse votre écran.`,
      },
      videoModalActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/style.scss";
</style>
