<template>
  <div class="fill-height">
    <component :is="setIs" :key="setIs.name"></component>
  </div>
</template>

<script>
import DisconnectHome from "../components/DisconnectHome/DisconnectHome";
import ConnectHome from "../components/ConnectHome/ConnectHome";

import user from "@/utils/mixins/user";

export default {
  name: "Home",

  mixins: [user],

  components: { DisconnectHome, ConnectHome },

  computed: {
    setIs() {
      this.isLogin;

      return this.isLogin ? ConnectHome : DisconnectHome;
    },
  },
};
</script>

<style></style>
