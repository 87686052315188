<template>
  <div class="fill-height">
    <main>
      <v-main class="fill-height pt-0"  style="padding-top: 60px">
        <vue-progress-bar></vue-progress-bar>
        <router-view />
      </v-main>
    </main>
    <CFooter />
  </div>
</template>

<script>
import CFooter from "@/components/cruip_template/layout/Footer.vue";

export default {
  name: "LayoutDefault",
  components: {
    // CHeader,
    CFooter,
    // AppBar,
  },
};
</script>
