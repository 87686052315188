<template>
  <v-container class="pt-10 mt-10">
    <v-row no-gutters>
      <body>
        <div class="Section0">
          <p
            style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >CONDITIONS GÉNÉRALES D'UTILISATION</span
            ><span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
            </span
            ><span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >DU SITE</span
            >
          </p>
          <p
            style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >www.market-digital.com</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 1. INFORMATIONS LÉGALES</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est
              précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son
              suivi.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Le site www.market-digital.com est édité par :</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >SAS MARKET DIGITAL, dont le siège siège social est situé à l'adresse suivante : 1 chemin de Belle Roche, 44240 LA
              CHAPELLE SUR ERDRE, et immatriculée au registre suivant : 894034297.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Téléphone : 0228299840/ Adresse e-mail : contact@market-digital.com.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Le directeur de publication du site est : Bastien BENGLER.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Le site www.market-digital.com est hébergé par :</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >netlify, dont le siège est situé à l'adresse ci-après :</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >2343 3rd Street Suite 296 • San Francisco, CA 94107</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 2. PRÉSENTATION DU SITE</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Le site www.market-digital.com a pour objet :</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Création de logiciel dédié au professionnels</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 3. CONTACT</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités
              illicites, l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante: contact@market-digital.com ou
              adresser un courrier recommandé avec accusé de réception à : SAS MARKET DIGITAL - 1 chemin de Belle Roche, 44240 LA
              CHAPELLE SUR ERDRE</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 4. ACCEPTATION DES CONDITIONS D'UTILISATION</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'accès et l'utilisation du site sont soumis à l'acceptation et au respect des présentes Conditions Générales
              d'Utilisation.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'éditeur se réserve le droit de modifier, à tout moment et sans préavis, le site et des services ainsi que les
              présentes CGU, notamment pour s'adapter aux évolutions du site par la mise à disposition de nouvelles
              fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Il est donc conseillé à l'utilisateur de se référer avant toute navigation à la dernière version des CGU,
              accessible à tout moment sur le site. En cas de désaccord avec les CGU, aucun usage du site ne saurait être effectué
              par l'utilisateur.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 5. ACCÈS ET NAVIGATION</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'accès au site et son utilisation sont réservés aux personnes majeures. L'éditeur sera en droit de demander une
              justification de l'âge de l'utilisateur, et ce par tout moyen.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'éditeur met en œuvre les solutions techniques à sa disposition pour permettre l'accès au site 24 heures sur 24, 7
              jours sur 7. Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l'accès au site ou à certaines
              pages de celui-ci afin de procéder à des mises à jours, des modifications de son contenu ou tout autre action jugée
              nécessaire au bon fonctionnement du site.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >La connexion et la navigation sur le site www.market-digital.com valent acceptation sans réserve des présentes
              Conditions Générales d'Utilisation, quelques soient les moyens techniques d'accès et les terminaux utilisés.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Les présentes CGU s'appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux
              sociaux et/ou communautaires existants ou à venir.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 6. GESTION DU SITE</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Pour la bonne gestion du site, l'éditeur pourra à tout moment :</span
            >
          </p>
          <ul type="disc" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines
                parties du site, à une catégorie déterminée d'internaute ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
                nationales ou internationales, ou avec les règles de la Nétiquette ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Suspendre le site afin de procéder à des mises à jour.</span
              >
            </li>
            <br />
          </ul>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 7. SERVICES RÉSERVÉS AUX UTILISATEURS INSCRITS</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >1. INSCRIPTION</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'accès à certains services et notamment à tous les services payants, est conditionné par l'inscription de
              l'utilisateur.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'inscription et l'accès aux services du site sont réservés exclusivement aux personnes physiques majeures
              capables, ayant rempli et validé le formulaire d'inscription disponible en ligne sur le site www.market-digital.com,
              ainsi que les présentes Conditions Générales d'Utilisation.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Lors de son inscription, l'utilisateur s'engage à fournir des informations exactes, sincères et à jour sur sa
              personne et son état civil. L'utilisateur devra en outre procéder à une vérification régulière des données le
              concernant afin d'en conserver l'exactitude.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'utilisateur doit ainsi fournir impérativement une adresse e-mail valide, sur laquelle le site lui adressera une
              confirmation de son inscription à ses services. Une adresse de messagerie électronique ne peut être utilisée
              plusieurs fois pour s'inscrire aux services.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Toute communication réalisée par www.market-digital.com et ses partenaires est en conséquence réputée avoir été
              réceptionnée et lue par l'utilisateur. Ce dernier s'engage donc à consulter régulièrement les messages reçus sur
              cette adresse e-mail et à répondre dans un délai raisonnable si cela est nécessaire.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Une seule inscription aux services du site est admise par personne physique.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'utilisateur se voit attribuer un identifiant lui permettant d'accéder à un espace dont l'accès lui est réservé
              (ci-après &quot;Espace personnel&quot;), en complément de la saisie de son mot de passe.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'identifiant est définitif, en revanche le mot de passe est modifiable en ligne par l'utilisateur dans son Espace
              personnel. Le mot de passe est personnel et confidentiel, l'utilisateur s'engage ainsi à ne pas le communiquer à des
              tiers.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >www.market-digital.com se réserve en tout état de cause la possibilité de refuser une demande d'inscription aux
              services en cas de non-respect par l'utilisateur des dispositions des présentes Conditions Générales
              d'Utilisation.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
          >
            <br /><span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >2. DÉSINSCRIPTION</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'utilisateur régulièrement inscrit pourra à tout moment demander sa désinscription en se rendant sur la page
              dédiée dans son Espace personnel. Toute désinscription du site sera effective immédiatement après que l'utilisateur
              ait rempli le formulaire prévu à cet effet.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
          >
            <br /><span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >3. SUPPRESSION DE L'ESPACE PERSONNEL À L'INITIATIVE DE L'ÉDITEUR DU SITE</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Il est porté à la connaissance de l'utilisateur que l'éditeur se réserve le droit de supprimer l'espace personnel
              de tout Utilisateur qui contreviendrait aux présentes conditions d'utilisation, et plus particulièrement dans les
              cas suivants :</span
            >
          </p>
          <ul type="disc" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Si l'utilisateur fait une utilisation illicite du site ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Si l'utilisateur, lors de la création de son espace personnel, transmet volontairement des informations erronées
                au site ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Si l'utilisateur n'a pas été actif sur son espace personnel depuis au moins un an.</span
              >
            </li>
          </ul>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Dans le cas où l'éditeur déciderait de supprimer l'espace personnel de l'utilisateur pour l'une de ces raisons,
              celle-ci ne saurait constituer un dommage pour l'utilisateur dont le compte a été supprimé.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Cette suppression ne saurait constituer une renonciation aux poursuites judiciaires que l'éditeur pourrait
              entreprendre à l'égard de l'utilisateur étant contrevenu à ces règles.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 8. RESPONSABILITÉS</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'éditeur n'est responsable que du contenu qu'il a lui-même édité.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'éditeur n'est pas responsable :</span
            >
          </p>
          <ul type="disc" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >En cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du site avec un matériel
                ou logiciel quel qu'il soit ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de
                l'utilisation ou des difficultés d'utilisation du site ou de ses services ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Des caractéristiques intrinsèques de l'Internet, notamment celles relatives au manque de fiabilité et au défaut
                de sécurisation des informations y circulant ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >Des contenus ou activités illicites utilisant son site et ce, sans qu'il en ait pris dûment connaissance au sens
                de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique et la Loi n°2004-801 du 6 août
                2004 relative à la protection des personnes physiques à l'égard de traitement de données à caractère
                personnel.</span
              >
            </li>
          </ul>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Par ailleurs, le site ne saurait garantir l'exactitude, la complétude, et l'actualité des informations qui y sont
              diffusées.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'utilisateur est responsable :</span
            >
          </p>
          <ul type="disc" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >De la protection de son matériel et de ses données ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >De l'utilisation qu'il fait du site ou de ses services ;</span
              >
            </li>

            <li
              style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >
              <span
                style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
                >S'il ne respecte ni la lettre, ni l'esprit des présentes CGU.</span
              >
            </li>
            <br />
          </ul>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 9. LIENS HYPERTEXTES</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Le site peut contenir des liens hypertextes pointant vers d'autres sites internet sur lesquels
              www.market-digital.com n'exerce pas de contrôle. Malgré les vérifications préalables et régulières réalisés par
              l'éditeur, celui-ci décline tout responsabilité quant aux contenus qu'il est possible de trouver sur ces
              sites.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son site sous réserve que
              la mise en place de ces liens ne soit pas réalisée à des fins commerciales ou publicitaires.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >En outre, l'information préalable de l'éditeur du site est nécessaire avant toute mise en place de lien
              hypertexte.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique,
              pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Enfin, www.market-digital.com se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers
              son site, si le site l'estime non conforme à sa politique éditoriale.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 10 : CONFIDENTIALITÉ</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >En plus des présentes Conditions Générales, le site dispose d'une politique de confidentialité qui décrit la
              manière dont les données à caractère personnel sont traitées lorsque l'utilisateur se rend sur le site, ainsi que la
              manière dont les cookies sont utilisés.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >En naviguant sur le site, l'utilisateur déclare avoir également pris connaissance de la politique de
              confidentialité susmentionnée.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 11. PROPRIÉTÉ INTELLECTUELLE</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >La structuration du site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications
              informatiques qui le composent sont la propriété de l'éditeur et sont protégés comme tels par les lois en vigueur au
              titre de la propriété intellectuelle.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées
              et services proposés par le site, par quelque procédé que ce soit, sans l'autorisation préalable, expresse et écrite
              de l'éditeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L.
              335-2 et suivants du Code de la propriété intellectuelle. Et ce, à l'exception des éléments expressément désignés
              comme libres de droits sur le site.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >L'accès au site ne vaut pas reconnaissance d'un droit et, de manière générale, ne confère aucun droit de propriété
              intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l'éditeur.</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Il est interdit à l'utilisateur d'introduire des données sur le site qui modifieraient ou qui seraient susceptibles
              d'en modifier le contenu ou l'apparence.</span
            >
          </p>
          <br />
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
              >ARTICLE 12. LOI APPLICABLE ET JURIDICTION COMPÉTENTE</span
            >
          </p>
          <p
            style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
              >Les présentes Conditions Générales d'Utilisation sont régies par la loi française. En cas de différend et à défaut
              d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en
              vigueur.</span
            >
          </p>
          <p
            style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
          >
            <span
              style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
              >Le site www.market-digital.com vous souhaite une excellente navigation !</span
            >
          </p>
          <p class="MyFooter" style="text-align: right">
            <!--[if supportFields
              ]><span style="mso-element: field-begin"></span
              ><span
                style="
              color: #808080;
              font-family: Helvetica;
              font-size: 10pt;
              text-transform: none;
              font-weight: normal;
              font-style: italic;
              font-variant: normal;
              line-height: 115%;
            "
                >PAGE </span
              ><span style="mso-element: field-separator"></span><!
            [endif]--><span
              style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
              >1</span
            ><!--[if supportFields]><span style="mso-element: field-end"></span><!
            [endif]--><span
              style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
              >/</span
            ><!--[if supportFields
              ]><span style="mso-element: field-begin"></span
              ><span
                style="
              color: #808080;
              font-family: Helvetica;
              font-size: 10pt;
              text-transform: none;
              font-weight: normal;
              font-style: italic;
              font-variant: normal;
              line-height: 115%;
            "
                >NUMPAGES </span
              ><span style="mso-element: field-separator"></span><!
            [endif]--><span
              style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
              >1</span
            ><!--[if supportFields]><span style="mso-element: field-end"></span><![endif]-->
          </p>
        </div></body></v-row
  ></v-container>
</template>

<script>
export default {
  name: "CGU",
};
</script>

<style scoped>
body {
  font-family: "Times New Roman";
  font-size: 1em;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
}
.Normal {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Default-Paragraph-Font {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Normal-Table {
}
.No-List {
}
.Heading-1-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 24pt;
  margin-bottom: 0pt;
  color: #365f91;
  font-family: Helvetica;
  font-size: 14pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-2-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 13pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-3-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-4-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Heading-5-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-6-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-7-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-8-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-9-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.annotation-reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.annotation-text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Comment-Text-Char-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.annotation-subject-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Comment-Subject-Char-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Balloon-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Tahoma;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Balloon-Text-Char-PHPDOCX {
  font-family: Tahoma;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-text-Car-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-Reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  vertical-align: super;
}
.endnote-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.endnote-text-Car-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.endnote-Reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  vertical-align: super;
}
.Default-Paragraph-Font-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.No-List-PHPDOCX {
}
.Heading-1-Car-PHPDOCX {
  color: #365f91;
  font-family: Helvetica;
  font-size: 14pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-2-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 13pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-3-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-4-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Heading-5-Car-PHPDOCX {
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-6-Car-PHPDOCX {
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-7-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Title-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 15pt;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  padding-bottom: 4pt;
  letter-spacing: 0.25pt;
  color: #17365d;
  font-family: Helvetica;
  font-size: 26pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Title-Car-PHPDOCX {
  letter-spacing: 0.25pt;
  color: #17365d;
  font-family: Helvetica;
  font-size: 26pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Subtitle-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  letter-spacing: 0.75pt;
  color: white;
  font-family: Helvetica;
  font-size: 12pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Subtitle-Car-PHPDOCX {
  letter-spacing: 0.75pt;
  color: white;
  font-family: Helvetica;
  font-size: 12pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Subtle-Emphasis-PHPDOCX {
  color: #808080;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Emphasis-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Intense-Emphasis-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Strong-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Quote-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Quote-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Intense-Quote-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 14pt;
  margin-left: 46.8pt;
  margin-right: 46.8pt;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  padding-bottom: 4pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Intense-Quote-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Subtle-Reference-PHPDOCX {
  color: #c0504d;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
  text-decoration: underline;
}
.Intense-Reference-PHPDOCX {
  letter-spacing: 0.25pt;
  color: #c0504d;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
  text-decoration: underline;
}
.Book-Title-PHPDOCX {
  letter-spacing: 0.25pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
}
.List-Paragraph-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  margin-left: 36pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.No-Spacing-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-8-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-9-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Normal-Table-PHPDOCX {
}
.Plain-Table-PHPDOCX {
}
.Table-Grid-PHPDOCX {
}
.Light-Shading-PHPDOCX {
}
.Light-Shading-Accent-1-PHPDOCX {
}
.Light-Shading-Accent-2-PHPDOCX {
}
.Light-Shading-Accent-3-PHPDOCX {
}
.Light-Shading-Accent-4-PHPDOCX {
}
.Light-Shading-Accent-5-PHPDOCX {
}
.Light-List-PHPDOCX {
}
.Light-List-Accent-1-PHPDOCX {
}
.Light-List-Accent-2-PHPDOCX {
}
.Light-List-Accent-3-PHPDOCX {
}
.Light-List-Accent-4-PHPDOCX {
}
.Light-List-Accent-5-PHPDOCX {
}
.Light-List-Accent-6-PHPDOCX {
}
.Light-Grid-PHPDOCX {
}
.Light-Grid-1-PHPDOCX {
}
.Light-Grid-2-PHPDOCX {
}
.Light-Grid-3-PHPDOCX {
}
.Light-Grid-4-PHPDOCX {
}
.Light-Grid-5-PHPDOCX {
}
.Light-Grid-6-PHPDOCX {
}
.Medium-Shading-1-PHPDOCX {
}
.Medium-Shading-1-Accent-1-PHPDOCX {
}
.Medium-Shading-1-Accent-2-PHPDOCX {
}
.Medium-Shading-1-Accent-3-PHPDOCX {
}
.Medium-Shading-1-Accent-4-PHPDOCX {
}
.Medium-Shading-1-Accent-5-PHPDOCX {
}
.Medium-Shading-1-Accent-6-PHPDOCX {
}
.Medium-Shading-2-PHPDOCX {
}
.Medium-Shading-2-Accent-1-PHPDOCX {
}
.Medium-Shading-2-Accent-2-PHPDOCX {
}
.Medium-Shading-2-Accent-3-PHPDOCX {
}
.Medium-Shading-2-Accent-4-PHPDOCX {
}
.Medium-Shading-2-Accent-5-PHPDOCX {
}
.Medium-Shading-2-Accent-6-PHPDOCX {
}
.Medium-List-1-PHPDOCX {
}
.Medium-List-1-Accent-1-PHPDOCX {
}
.Medium-List-1-Accent-2-PHPDOCX {
}
.Medium-List-1-Accent-3-PHPDOCX {
}
.Medium-List-1-Accent-4-PHPDOCX {
}
.Medium-List-1-Accent-5-PHPDOCX {
}
.Medium-List-1-Accent-6-PHPDOCX {
}
.Medium-List-2-PHPDOCX {
}
.Medium-List-2-Accent-1-PHPDOCX {
}
.Medium-List-2-Accent-2-PHPDOCX {
}
.Medium-List-2-Accent-3-PHPDOCX {
}
.Medium-List-2-Accent-4-PHPDOCX {
}
.Medium-List-2-Accent-5-PHPDOCX {
}
.Medium-List-2-Accent-6-PHPDOCX {
}
.Medium-Grid-1-PHPDOCX {
}
.Medium-Grid-1-Accent-1-PHPDOCX {
}
.Medium-Grid-1-Accent-2-PHPDOCX {
}
.Medium-Grid-1-Accent-3-PHPDOCX {
}
.Medium-Grid-1-Accent-4-PHPDOCX {
}
.Medium-Grid-1-Accent-5-PHPDOCX {
}
.Medium-Grid-1-Accent-6-PHPDOCX {
}
.Medium-Grid-2-PHPDOCX {
}
.Medium-Grid-2-Accent-1-PHPDOCX {
}
.Medium-Grid-2-Accent-2-PHPDOCX {
}
.Medium-Grid-2-Accent-3-PHPDOCX {
}
.Medium-Grid-2-Accent-4-PHPDOCX {
}
.Medium-Grid-2-Accent-5-PHPDOCX {
}
.Medium-Grid-2-Accent-6-PHPDOCX {
}
.Medium-Grid-3-PHPDOCX {
}
.Medium-Grid-3-Accent-1-PHPDOCX {
}
.Medium-Grid-3-Accent-2-PHPDOCX {
}
.Medium-Grid-3-Accent-3-PHPDOCX {
}
.Medium-Grid-3-Accent-5-PHPDOCX {
}
.Medium-Grid-3-Accent-4-PHPDOCX {
}
.Medium-Grid-3-Accent-6-PHPDOCX {
}
.Dark-List-PHPDOCX {
}
.Dark-List-Accent-1-PHPDOCX {
}
.Dark-List-Accent-2-PHPDOCX {
}
.Dark-List-Accent-3-PHPDOCX {
}
.Dark-List-Accent-4-PHPDOCX {
}
.Dark-List-Accent-5-PHPDOCX {
}
.Dark-List-Accent-6-PHPDOCX {
}
.Colorful-Shading-PHPDOCX {
}
.Colorful-Shading-Accent-1-PHPDOCX {
}
.Colorful-Shading-Accent-2-PHPDOCX {
}
.Colorful-Shading-Accent-3-PHPDOCX {
}
.Colorful-Shading-Accent-4-PHPDOCX {
}
.Colorful-Shading-Accent-5-PHPDOCX {
}
.Colorful-Shading-Accent-6-PHPDOCX {
}
.Colorful-List-PHPDOCX {
}
.Colorful-List-Accent-1-PHPDOCX {
}
.Colorful-List-Accent-2-PHPDOCX {
}
.Colorful-List-Accent-3-PHPDOCX {
}
.Colorful-List-Accent-4-PHPDOCX {
}
.Colorful-List-Accent-5-PHPDOCX {
}
.Colorful-List-Accent-6-PHPDOCX {
}
.Colorful-Grid-PHPDOCX {
}
.Colorful-Grid-Accent-1-PHPDOCX {
}
.Colorful-Grid-Accent-2-PHPDOCX {
}
.Colorful-Grid-Accent-3-PHPDOCX {
}
.Colorful-Grid-Accent-4-PHPDOCX {
}
.Colorful-Grid-Accent-5-PHPDOCX {
}
.Colorful-Grid-Accent-6-PHPDOCX {
}
.MyFooter {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  color: #808080;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.MyFooterCar {
  color: #808080;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
</style>
