<template>
  <div class="brand">
    <h1 class="m-0">
      <router-link to="/">
        <c-image
          :src="require('@/assets/logo/stamp white.svg')"
          alt="market-digital"
          :width="70"
          :height="70"
        />
      </router-link>
    </h1>
  </div>
</template>

<script>
import CImage from "@/components/cruip_template/elements/Image.vue";

export default {
  name: "CLogo",
  components: {
    CImage
  }
};
</script>

