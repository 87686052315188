<template>
  <div class="neon-cruip">
    <div class="has-animations is-loaded">
      <!-- "app" pose probleme ici -->
      <!-- :app="$route.fullPath == '/'" -->
      <v-app-bar app absolute flat color="transparent" class="black-gradiant pb-15 mb-15">
        <c-logo class="ml-md-10 pl-md-10" />

        <router-link
          to="/"
          tag="div"
          class="mt-lg-6 mt-6 ml-12 font-weight-light text-uppercase text--primary spacing pointer"
          :class="{ 'text-h4': $vuetify.breakpoint.xlOnly, 'text-h6': $vuetify.breakpoint.lgAndDown }"
          style="min-width: 200px"
          v-if="$vuetify.breakpoint.lgAndUp"
          ><span class="">Market-</span><span class="digital-color">digital</span>
        </router-link>
        <v-spacer></v-spacer>

        <div id="contact-info" class="flex-row mr-lg-10 pr-lg-10 text-right mb-sm-2 mb-0 pt-sm-3 pt-0">
          <span class="text-caption font-weight-black"> 06 64 05 41 39 | contact@market-digital.com</span>
        </div>
        <v-menu class="d-flex d-lg-none" bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon class="d-flex d-lg-none" v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="!hideSignin">
              <v-list-item-title>
                <v-btn to="/contact" class="font-weight-bold text-uppercase btn-spacing" text block>Nous contacter </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hideSignin && !isLogin">
              <v-list-item-title>
                <v-btn to="essai-gratuit" class="font-weight-bold text-uppercase gradiant-market-digital btn-spacing" block
                  >Essai gratuit
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hideSignin && !isLogin">
              <v-list-item-title
                ><v-list-item-title>
                  <v-btn to="/login" class="font-weight-bold btn-spacing" block outlined>Se connecter </v-btn>
                </v-list-item-title>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!hideSignin && isLogin">
              <v-list-item-title
                ><v-list-item-title>
                  <v-btn to="/logout" class="font-weight-bold btn-spacing" outlined block>Se déconnecter</v-btn>
                </v-list-item-title>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div class="d-none d-lg-flex">
          <!-- <div id="contact-info" class="mb-2 flex-row mr-md-10 pr-md-10 text-right" style="padding-top: 20px;">
            <span class="caption font-weight-black"> 06 64 05 41 39 | contact@market-digital.com</span>
          </div> -->
          <div class="d-flex flex-wrap justify-end">
            <div v-if="!hideSignin" class="mr-md-5 pr-md-5 ">
              <v-btn to="/contact" class="font-weight-bold text-uppercase btn-spacing pa-3 my-1 my-md-0 mr-md-0" text
                >Nous contacter</v-btn
              >
            </div>
            <div v-if="!hideSignin && !isLogin" class="mr-md-5 pr-md-5 text-end">
              <v-btn
                to="essai-gratuit"
                class="font-weight-bold text-uppercase gradiant-market-digital btn-spacing pa-3 mr-3 my-1 my-md-0 mr-md-0 "
                >Essai gratuit</v-btn
              >
            </div>
            <div v-if="!hideSignin && !isLogin" class="mr-md-5 pr-md-5 text-end">
              <v-btn to="/login" class="font-weight-bold btn-spacing pa-3 my-1 mr-3 mr-md-0 my-md-0" outlined>Se connecter</v-btn>
            </div>
            <div v-if="!hideSignin && isLogin" class="mr-md-5 pr-md-5 text-end">
              <v-btn to="/logout" class="font-weight-bold btn-spacing pa-3 my-1 mr-3 mr-md-0 my-md-0" outlined
                >Se déconnecter</v-btn
              >
            </div>
          </div>
        </div>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";
import CLogo from "@/components/cruip_template/layout/partials/Logo.vue";

export default {
  name: "AppBar",

  mixins: [user],

  data() {
    return {
      isActive: false,
    };
  },

  components: {
    CLogo,
  },

  props: {
    active: Boolean,
    navPosition: {
      type: String,
      default: "",
    },
    hideNav: {
      type: Boolean,
      default: false,
    },
    hideSignin: {
      type: Boolean,
      default: false,
    },
    bottomOuterDivider: {
      type: Boolean,
      default: false,
    },
    bottomDivider: {
      type: Boolean,
      default: false,
    },
  },

  methods: {},

  computed: {},
};
</script>

<style lang="scss" scoped>
.black-gradiant {
  background: linear-gradient(#000000, #9198e500);
}

.btn-spacing {
  letter-spacing: 0.06em;
}

.digital-color {
  color: #4883d3;
}

.spacing {
  letter-spacing: 5px !important;
}
</style>
