<template>
  <c-login-form bottomOuterDivider />
</template>

<script>
import CLayout from "@/components/cruip_template/layouts/LayoutSignin";

import CLoginForm from "@/components/cruip_template/sections/LoginForm.vue";

export default {
  name: "Login",
  components: {
    CLoginForm
  }
};
</script>

<style lang="scss" scoped>
</style>