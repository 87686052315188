<template>
  <main>
    <v-main class="fill-height pt-0"  style="padding-top: 60px">
      <vue-progress-bar></vue-progress-bar>
      <router-view />
    </v-main>
  </main>
</template>

<script>
import CHeader from "@/components/cruip_template/layout/Header.vue";
export default {
  components: {
    CHeader,
  },
};
</script>
