<template>
  <v-container fluid class="fill-height neon-cruip pa-0">
    <v-row justify="center" aligne="center" class="has-animations is-loaded fill-height illustration-section-02" no-gutters>
      <section
        class="signin section mt-10 pt-10"
        :class="[
          topOuterDivider && 'has-top-divider',
          bottomOuterDivider && 'has-bottom-divider',
          hasBgColor && 'has-bg-color',
          invertColor && 'invert-color',
        ]"
      >
        <v-container class="">
          <div
            class="signin-inner section-inner"
            :class="[topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider']"
          >
            <c-section-header tag="h1" :data="sectionHeader" class="center-content reveal-from-left" />

            <v-form ref="form" v-model="valid" lazy-validation>
              <fieldset>
                <div class="mb-2 reveal-from-bottom">
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    label="Identifiant"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    class="py-2"
                    v-on:keyup.enter="validate"
                    persistent-hint
                    required
                  />
                </div>
                <div class="mb-2 reveal-from-bottom">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    id="password"
                    label="Mot de passe"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    class="pt-3"
                    v-on:keyup.enter="validate"
                    required
                  />
                </div>

                <div class="my-4" v-if="show_screens">
                  <v-autocomplete
                    v-model="selected_screen"
                    solo
                    :items="screen_list"
                    chips
                    filled
                    dense
                    label="Choix de l'écran"
                  ></v-autocomplete>
                </div>
                <div class="mt-24 mb-32">
                  <v-btn
                    color="indigo accent-2"
                    class="text-uppercase font-weight-bold"
                    large
                    @click="validate"
                    :loading="loading"
                    block
                    >Connexion</v-btn
                  >
                </div>
                <!-- <div class="signin-footer mb-32">
                          <c-checkbox>Remember me</c-checkbox>
                          <router-link
                            to="/recover-password/"
                            class="func-link text-xs"
                          >Forgot password?</router-link>
                      </div>-->
              </fieldset>
            </v-form>
            <!-- <div class="signin-bottom has-top-divider">
                      <div class="pt-32 text-xs center-content text-color-low">
                        Don't you have an account?
                        <router-link to="/signup/" class="func-link">Sign up</router-link>
                      </div>
                  </div>-->
          </div>
        </v-container>
      </section>
    </v-row>
  </v-container>
</template>

<script>
import { SectionProps } from "@/utils/SectionProps.js";
import CSectionHeader from "@/components/cruip_template/sections/partials/SectionHeader.vue";
import CInput from "@/components/cruip_template/elements/Input.vue";
import CButton from "@/components/cruip_template/elements/Button.vue";
import CCheckbox from "@/components/cruip_template/elements/Checkbox.vue";

import snackBar from "@/utils/mixins/snackBar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CSignupForm",
  components: {
    CSectionHeader,
    CInput,
    CButton,
    CCheckbox,
  },
  mixins: [SectionProps, snackBar],
  data() {
    return {
      sectionHeader: {
        title: "Animez votre espace d'attente maintenant",
      },
      valid: false,
      username: "",
      usernameRules: [(v) => !!v || "Un identifiant est nécessaire"],
      password: "",
      passwordRules: [(v) => !!v || "Mot de passe incorrect"],
      loading: false,

      // SCREENS
      show_screens: false,
      screen_list: [],
      selected_screen: null,
    };
  },
  methods: {
    ...mapActions(["set_user"]),

    validate() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },

    async login() {
      this.loading = true;
      try {
        let { data, status } = await this.$axios.post("/login", {
          username: this.username,
          password: this.password,
          screen: this.selected_screen,
        });

        if (status === 255) this.handleSelectScreen(data);
        else if (status === 266) this.handleSessionActive();
        else {
          this.reset_user(data);
          this.redirect();
        }
      } catch (err) {
        console.log(err);
        this.password = "";
        this.valid = false;
      }
      this.loading = false;
    },

    async handleSelectScreen(data) {
      this.displayInfoSnack(`Veuillez choisir un écran parmis la liste des écrans que vous possédez`);
      this.show_screens = true;
      this.screen_list = data;
    },

    async handleSessionActive() {
      if (confirm("Une session lié à votre compte est déjà active ailleurs. Souhaitez-vous quand même vous connecter ?")) {
        let { data, status } = await this.$axios.post("/login", {
          username: this.username,
          password: this.password,
          screen: this.selected_screen,
          override: true,
        });
        this.reset_user(data);
        this.redirect();
      }
    },

    reset_user(whoami) {
      this.set_user({ ...this.get_user, ...whoami });
    },

    redirect() {
      if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect);
      else this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["get_user"]),
  },
};
</script>
