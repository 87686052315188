<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center" class="text-center fill-height" no-gutters>
      <div v-for="(section, idx) in sections" :key="section.title" v-show="show[idx]" cols="5" class="ma-5">
        <!-- <v-slide-y-transition group> -->
        <v-card class="mx-auto" max-width="400" link :to="section.to" :key="section.title">
          <v-img class="white--text align-end" height="200px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg">
            <v-card-title class="text-h4 font-weight-regular">{{ section.title }}</v-card-title>
          </v-img>

          <v-card-subtitle class="text-left text-caption">{{ section.role }}</v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ section.label }}</div>
          </v-card-text>

          <v-card-actions class=" py-1 orange"> </v-card-actions>
        </v-card>
        <!-- </v-slide-y-transition> -->
      </div>
    </v-row>
  </v-container>
</template>

<script>
import snackBar from "@/utils/mixins/snackBar";
export default {
  name: "ConnectHome",

  mixins: [snackBar],

  data() {
    return {
      show: [true, false, false, false],

      sections: [
        {
          title: "Mode Ecran",
          to: { name: "options diapo" },
          role: "diapo",
          label: 'Vous avez accès au diaporama car vous avez le privilège "diapo"',
        },
        {
          title: "Données Site",
          to: { name: "company.concession", params: { type: "concession" } },
          // role: "company.concession",
          label: 'Vous avez accès à la gestion des données de la concession car vous avez le privilège "company.concession" ',
        },
        {
          title: "Données Groupe",
          to: { name: "company.concession", params: { type: "groupe" } },
          // role: "company.groupe",
          label: 'Vous avez accès à la gestion des données du groupe car vous avez le privilège "company.groupe" ',
        },
        {
          title: "Données Franchise/Marques",
          to: { name: "company.concession", params: { type: "franchise" } },
          // role: "company.franchise",
          label: 'Vous avez accès à la gestion des données de la franchise car vous avez le privilège "company.franchise" ',
        },
      ],
    };
  },

  async created() {
    try {
      let concession = (await this.$axios.get("/concession/access")).data; // should be replaced with a request that check if we actually have the privilege "company.concession"
      let groupe = (await this.$axios.get("/groupe/access")).data;
      let franchise = (await this.$axios.get("/franchise/access")).data;
      // TODO : CHECK IF CARDIFF - IF NO => NO PHOTO SO DO NOT ALLOW HIM TO ACCESS TO DIAPO

      if (concession?.access) {
        this.show.splice(1, 1, true);
      }
      if (groupe?.access) this.show.splice(2, 1, true);
      if (franchise?.access) this.show.splice(3, 1, true);
    } catch (error) {
      console.error(error);
      this.displayErrorSnack(error);
    }
  },

  computed: {
    getSections() {
      return this.sections;
    },
  },
};
</script>

<style lang="scss" scoped></style>
