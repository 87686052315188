<template>
  <v-row class="flex-column flex-sm-row text-md-left" justify="center" align="center">
    <v-col cols="9" md="6" lg="4" class="mb-10 mb-md-0 mt-10 pt-10 pt-md-0 mt-md-0 contour">
      <div
        class="mt-0 mb-10 reveal-from-bottom text-h4 font-weight-medium"
        data-reveal-delay="150"
        style="text-shadow: 3px 3px 3px black;"
      >
        Prenez le contrôle de vos écrans et optimisez votre business
      </div>
      <p class="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="300" style="text-shadow: 3px 3px 3px black;">
        Habillez votre espace d'attente ou l'accueil de votre site avec un écran diffusant les plus belles photos de vos véhicules
        en vente, et bien plus encore !
      </p>
      <div class="reveal-from-bottom" data-reveal-delay="450">
        <v-btn
          block
          class="gradiant-market-digital font-weight-black text-uppercase btn-spacing"
          @click="$vuetify.goTo('#CTA', { duration: 600, easing: 'easeInOutCubic' })"
          >Essai gratuit</v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
