<template>
  <v-container class="pt-10 mt-10">
    <v-row no-gutters>
      <div class="Section0">
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >POLITIQUE DE CONFIDENTIALITÉ</span
          >
        </p>
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >DU SITE :</span
          >
        </p>
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >www.market-digital.com</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: normal;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            font-family: Helvetica;
            font-size: 11pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >&#xa0;</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 1 : PRÉAMBULE</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Cette politique de confidentialité s'applique au site : www.market-digital.com.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :</span
          >
        </p>
        <ul type="disc" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées comme
              données personnelles toutes les données étant susceptibles d'identifier un utilisateur. Il s'agit notamment du
              prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation de l'utilisateur ou encore son
              adresse IP ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Quels sont les droits des utilisateurs concernant ces données ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Qui est responsable du traitement des données à caractère personnel collectées et traitées ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >A qui ces données sont transmises ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Eventuellement, la politique du site en matière de fichiers &quot;cookies&quot;.</span
            >
          </li>
          <br />
        </ul>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les
            utilisateurs peuvent consulter à l'adresse ci-après :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >www.market-digital.com/#/CGU</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données
            des utilisateurs du site respectent les principes suivants :</span
          >
        </p>
        <ul type="disc" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de
              l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il
              sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont
              collectées ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs
              objectifs déterminés dans les présentes conditions générales d'utilisation ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des
              objectifs poursuivis par le site sont collectées ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont
              l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des
              critères utilisés pour déterminer la durée de conservation ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage
              à garantir l'intégrité et la confidentialité des données collectées.</span
            >
          </li>
          <br />
        </ul>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et
            le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des
            conditions ci-après énumérées :</span
          >
        </p>
        <ul type="disc" style="margin: 0pt; padding-left: 0pt">
          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >L'utilisateur a expressément consenti au traitement ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Le traitement est nécessaire à la bonne exécution d'un contrat ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Le traitement répond à une obligation légale ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou
              d'une autre personne physique ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de
              l'exercice de l'autorité publique ;</span
            >
          </li>

          <li
            style="
            text-align: justify;
            page-break-inside: auto;
            page-break-after: auto;
            page-break-before: avoid;
            line-height: normal;
            margin-top: 0pt;
            margin-bottom: 0pt;
            -sf-number-width: 11.51953pt;
            margin-left: 29.5195313pt;
            padding-left: 6.48046875pt;
            text-indent: 0pt;
            color: white;
            font-family: Symbol;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            <span
              style="
              color: white;
              font-family: Helvetica;
              font-size: 12pt;
              text-transform: none;
              font-weight: normal;
              font-style: normal;
              font-variant: normal;
            "
              >Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et
              privés poursuivis par le responsable du traitement ou par un tiers.</span
            >
          </li>
          <br />
        </ul>
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >A.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Les données à caractère personnel collectées sur le site www.market-digital.com sont les suivantes :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span style="font: 7pt 'Times New Roman'; -sf-listtab: yes"> </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Prénom, Nom</span
          ><span
            style="
            width: 32.18pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Adresse </span
          ><span
            style="
            width: 20.11pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Code postal </span
          ><span
            style="
            width: 0.09pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Ville</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Fonction
          </span>
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span style="font: 7pt 'Times New Roman'; -sf-listtab: yes"> </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Nom de l’entreprise</span
          ><span
            style="
            width: 2.16pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Email professionnel</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Secteur activité
          </span>
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span style="font: 7pt 'Times New Roman'; -sf-listtab: yes"> </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Autre email</span
          ><span
            style="
            width: 10.11pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Téléphone Portable</span
          ><span
            style="
            width: 33.52pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            line-height: 115%;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >Téléphone professionnel</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: 115%;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 115%;
          "
            >URL compte professionnel</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          line-height: normal;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            font-family: Helvetica;
            font-size: 11pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >&#xa0;</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Lorsque l’utilisateur utilise le formulaire de contact pour envoyer une demande.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables
            de sécurité l'ensemble des données collectées pour une durée de : - Documents contractuels, commerciaux : 3 ans.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >La collecte et le traitement des données répondent aux finalités suivantes :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >- Réponse aux demande envoyées par le biais du formulaire de contact</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Les traitements de données effectués sont fondés sur les bases légales suivantes :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span style="font: 7pt 'Times New Roman'; -sf-listtab: yes"> </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >- Consentement de l’utilisateur </span
          ><span
            style="
            width: 11.58pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >- Obligation légale</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >B.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            TRANSMISSION DES DONNÉES A DES TIERS</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que
            par l'éditeur du site.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >C.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            HÉBERGEMENT DES DONNÉES</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le site www.market-digital.com est hébergé par : NETLIFY, dont le siège est situé à l'adresse ci-après :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >2343 3rd Street Suite 296 • San Francisco, CA 94107</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Les données collectées et traitées par le site sont transférées vers le(s) pays suivant(s) : ALLEMAGNE.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >A.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            LE RESPONSABLE DU TRAITEMENT DES DONNÉES</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable du traitement des données à caractère personnel est : Bastien BENGLER. Il peut être contacté de la
            manière suivante :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span style="font: 7pt 'Times New Roman'; -sf-listtab: yes"> </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable des données peut etre contacté par email a :</span
          ><span
            style="
            width: 31.12pt;
            text-indent: 0pt;
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            font-weight: normal;
            font-style: normal;
            display: inline-block;
          "
          >
          </span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
          </span
          ><br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >contact@market-digital.com</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du
            traitement des données à caractère personnel.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >B.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les
            transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces
            données ont été collectées.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par
            le site sont sécurisés.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Un certificat SSL (&quot;Secure Socket Layer&quot; Certificate) a pour but de sécuriser les données échangées entre
            l'utilisateur et le site.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de
            suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches
            disproportionnés.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est
            compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 5 : DROITS DE L'UTILISATEUR</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède
            les droits ci-après énumérés.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui
            communiquer : ses prénom et nom ainsi que son adresse e-mail, et si cela est pertinent, son numéro de compte ou
            d'espace personnel ou d'abonné.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours
            maximum.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >A.</span
          ><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
          >
            PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >a. Droit d'accès, de rectification et droit à l'effacement</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le
            concernant, en respectant la procédure ci-après énoncée :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L’utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l’objet de
            sa demande, à l’adresse e-mail du contact.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la
            procédure suivante :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L’utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant ces
            coordonnées, à l’adresse e-mail du contact. La demande sera traitée dans un délai de 10 jours ouvrés.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >b. Droit à la portabilité des données</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre
            site, en se conformant à la procédure ci-après :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L’utilisateur doit faire une demande de portabilité de ses données personnelles auprès du responsable du traitement
            des données en envoyant un e-mail à l’adresse prévue ci-dessus.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >c. Droit à la limitation et à l'opposition du traitement des données</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que
            le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les
            intérêts et les droits et libertés de l'utilisateur.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses
            données, l'utilisateur doit suivre la procédure suivante :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L’utilisateur doit faire une demande de limitation au traitement de ses données personnelles par e-mail auprès du
            responsable du traitement des données.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision
            fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou
            l'affecte de manière significative de façon similaire.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >e. Droit de déterminer le sort des données après la mort</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées
            s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
          "
            >f. Droit de saisir l'autorité de contrôle compétente</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et
            que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits
            énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés,
            https://www.cnil.fr) ou tout juge compétent.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée :</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
          margin-left: 22.5pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >https://market-digital.com/#/confidentialite</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de
            se tenir informé des derniers changements qui lui seront apportés.</span
          >
        </p>
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de
            confidentialité est intervenue le : 30/03/2021.</span
          >
        </p>
        <br />
        <p
          style="
          text-align: center;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: bold;
            font-style: normal;
            font-variant: normal;
          "
            >ARTICLE 7 : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE CONFIDENTIALITÉ</span
          >
        </p>
        <br />
        <p
          style="
          text-align: justify;
          page-break-inside: auto;
          page-break-after: auto;
          page-break-before: avoid;
          margin-top: 12pt;
          margin-bottom: 12pt;
        "
        >
          <br /><span
            style="
            color: white;
            font-family: Helvetica;
            font-size: 12pt;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
          "
            >En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en
            accepte les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de ses données à
            caractère personnel.</span
          >
        </p>
        <p class="MyFooter" style="text-align: right">
          <!--[if supportFields
            ]><span style="mso-element: field-begin"></span
            ><span
              style="
              color: #808080;
              font-family: Helvetica;
              font-size: 10pt;
              text-transform: none;
              font-weight: normal;
              font-style: italic;
              font-variant: normal;
              line-height: 115%;
            "
              >PAGE </span
            ><span style="mso-element: field-separator"></span><!
          [endif]--><span
            style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
            >1</span
          ><!--[if supportFields]><span style="mso-element: field-end"></span><!
          [endif]--><span
            style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
            >/</span
          ><!--[if supportFields
            ]><span style="mso-element: field-begin"></span
            ><span
              style="
              color: #808080;
              font-family: Helvetica;
              font-size: 10pt;
              text-transform: none;
              font-weight: normal;
              font-style: italic;
              font-variant: normal;
              line-height: 115%;
            "
              >NUMPAGES </span
            ><span style="mso-element: field-separator"></span><!
          [endif]--><span
            style="
            color: #808080;
            font-family: Helvetica;
            font-size: 10pt;
            text-transform: none;
            font-weight: normal;
            font-style: italic;
            font-variant: normal;
            line-height: 115%;
          "
            >1</span
          ><!--[if supportFields]><span style="mso-element: field-end"></span><![endif]-->
        </p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Confidentialite",
};
</script>

<style scoped>
body {
  font-family: "Times New Roman";
  font-size: 1em;
  color: white;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
}
.Normal {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Default-Paragraph-Font {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Normal-Table {
}
.No-List {
}
.Heading-1-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 24pt;
  margin-bottom: 0pt;
  color: #365f91;
  font-family: Helvetica;
  font-size: 14pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-2-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 13pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-3-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-4-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Heading-5-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-6-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-7-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-8-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-9-PHPDOCX {
  text-align: left;
  page-break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 0pt;
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.annotation-reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.annotation-text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Comment-Text-Char-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.annotation-subject-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 10pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Comment-Subject-Char-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Balloon-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Tahoma;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Balloon-Text-Char-PHPDOCX {
  font-family: Tahoma;
  font-size: 8pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-text-Car-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.footnote-Reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  vertical-align: super;
}
.endnote-Text-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.endnote-text-Car-PHPDOCX {
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.endnote-Reference-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  vertical-align: super;
}
.Default-Paragraph-Font-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.No-List-PHPDOCX {
}
.Heading-1-Car-PHPDOCX {
  color: #365f91;
  font-family: Helvetica;
  font-size: 14pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-2-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 13pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-3-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Heading-4-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Heading-5-Car-PHPDOCX {
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-6-Car-PHPDOCX {
  color: #243f60;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Heading-7-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Title-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 15pt;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  padding-bottom: 4pt;
  letter-spacing: 0.25pt;
  color: #17365d;
  font-family: Helvetica;
  font-size: 26pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Title-Car-PHPDOCX {
  letter-spacing: 0.25pt;
  color: #17365d;
  font-family: Helvetica;
  font-size: 26pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Subtitle-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  letter-spacing: 0.75pt;
  color: white;
  font-family: Helvetica;
  font-size: 12pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Subtitle-Car-PHPDOCX {
  letter-spacing: 0.75pt;
  color: white;
  font-family: Helvetica;
  font-size: 12pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Subtle-Emphasis-PHPDOCX {
  color: #808080;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Emphasis-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Intense-Emphasis-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Strong-PHPDOCX {
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
.Quote-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Quote-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Intense-Quote-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 10pt;
  margin-bottom: 14pt;
  margin-left: 46.8pt;
  margin-right: 46.8pt;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  padding-bottom: 4pt;
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Intense-Quote-Car-PHPDOCX {
  color: white;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: italic;
  font-variant: normal;
}
.Subtle-Reference-PHPDOCX {
  color: #c0504d;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
  text-decoration: underline;
}
.Intense-Reference-PHPDOCX {
  letter-spacing: 0.25pt;
  color: #c0504d;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
  text-decoration: underline;
}
.Book-Title-PHPDOCX {
  letter-spacing: 0.25pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
}
.List-Paragraph-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  margin-left: 36pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.No-Spacing-PHPDOCX {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-family: Helvetica;
  font-size: 11pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-8-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
.Heading-9-Car-PHPDOCX {
  color: #404040;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.Normal-Table-PHPDOCX {
}
.Plain-Table-PHPDOCX {
}
.Table-Grid-PHPDOCX {
}
.Light-Shading-PHPDOCX {
}
.Light-Shading-Accent-1-PHPDOCX {
}
.Light-Shading-Accent-2-PHPDOCX {
}
.Light-Shading-Accent-3-PHPDOCX {
}
.Light-Shading-Accent-4-PHPDOCX {
}
.Light-Shading-Accent-5-PHPDOCX {
}
.Light-List-PHPDOCX {
}
.Light-List-Accent-1-PHPDOCX {
}
.Light-List-Accent-2-PHPDOCX {
}
.Light-List-Accent-3-PHPDOCX {
}
.Light-List-Accent-4-PHPDOCX {
}
.Light-List-Accent-5-PHPDOCX {
}
.Light-List-Accent-6-PHPDOCX {
}
.Light-Grid-PHPDOCX {
}
.Light-Grid-1-PHPDOCX {
}
.Light-Grid-2-PHPDOCX {
}
.Light-Grid-3-PHPDOCX {
}
.Light-Grid-4-PHPDOCX {
}
.Light-Grid-5-PHPDOCX {
}
.Light-Grid-6-PHPDOCX {
}
.Medium-Shading-1-PHPDOCX {
}
.Medium-Shading-1-Accent-1-PHPDOCX {
}
.Medium-Shading-1-Accent-2-PHPDOCX {
}
.Medium-Shading-1-Accent-3-PHPDOCX {
}
.Medium-Shading-1-Accent-4-PHPDOCX {
}
.Medium-Shading-1-Accent-5-PHPDOCX {
}
.Medium-Shading-1-Accent-6-PHPDOCX {
}
.Medium-Shading-2-PHPDOCX {
}
.Medium-Shading-2-Accent-1-PHPDOCX {
}
.Medium-Shading-2-Accent-2-PHPDOCX {
}
.Medium-Shading-2-Accent-3-PHPDOCX {
}
.Medium-Shading-2-Accent-4-PHPDOCX {
}
.Medium-Shading-2-Accent-5-PHPDOCX {
}
.Medium-Shading-2-Accent-6-PHPDOCX {
}
.Medium-List-1-PHPDOCX {
}
.Medium-List-1-Accent-1-PHPDOCX {
}
.Medium-List-1-Accent-2-PHPDOCX {
}
.Medium-List-1-Accent-3-PHPDOCX {
}
.Medium-List-1-Accent-4-PHPDOCX {
}
.Medium-List-1-Accent-5-PHPDOCX {
}
.Medium-List-1-Accent-6-PHPDOCX {
}
.Medium-List-2-PHPDOCX {
}
.Medium-List-2-Accent-1-PHPDOCX {
}
.Medium-List-2-Accent-2-PHPDOCX {
}
.Medium-List-2-Accent-3-PHPDOCX {
}
.Medium-List-2-Accent-4-PHPDOCX {
}
.Medium-List-2-Accent-5-PHPDOCX {
}
.Medium-List-2-Accent-6-PHPDOCX {
}
.Medium-Grid-1-PHPDOCX {
}
.Medium-Grid-1-Accent-1-PHPDOCX {
}
.Medium-Grid-1-Accent-2-PHPDOCX {
}
.Medium-Grid-1-Accent-3-PHPDOCX {
}
.Medium-Grid-1-Accent-4-PHPDOCX {
}
.Medium-Grid-1-Accent-5-PHPDOCX {
}
.Medium-Grid-1-Accent-6-PHPDOCX {
}
.Medium-Grid-2-PHPDOCX {
}
.Medium-Grid-2-Accent-1-PHPDOCX {
}
.Medium-Grid-2-Accent-2-PHPDOCX {
}
.Medium-Grid-2-Accent-3-PHPDOCX {
}
.Medium-Grid-2-Accent-4-PHPDOCX {
}
.Medium-Grid-2-Accent-5-PHPDOCX {
}
.Medium-Grid-2-Accent-6-PHPDOCX {
}
.Medium-Grid-3-PHPDOCX {
}
.Medium-Grid-3-Accent-1-PHPDOCX {
}
.Medium-Grid-3-Accent-2-PHPDOCX {
}
.Medium-Grid-3-Accent-3-PHPDOCX {
}
.Medium-Grid-3-Accent-5-PHPDOCX {
}
.Medium-Grid-3-Accent-4-PHPDOCX {
}
.Medium-Grid-3-Accent-6-PHPDOCX {
}
.Dark-List-PHPDOCX {
}
.Dark-List-Accent-1-PHPDOCX {
}
.Dark-List-Accent-2-PHPDOCX {
}
.Dark-List-Accent-3-PHPDOCX {
}
.Dark-List-Accent-4-PHPDOCX {
}
.Dark-List-Accent-5-PHPDOCX {
}
.Dark-List-Accent-6-PHPDOCX {
}
.Colorful-Shading-PHPDOCX {
}
.Colorful-Shading-Accent-1-PHPDOCX {
}
.Colorful-Shading-Accent-2-PHPDOCX {
}
.Colorful-Shading-Accent-3-PHPDOCX {
}
.Colorful-Shading-Accent-4-PHPDOCX {
}
.Colorful-Shading-Accent-5-PHPDOCX {
}
.Colorful-Shading-Accent-6-PHPDOCX {
}
.Colorful-List-PHPDOCX {
}
.Colorful-List-Accent-1-PHPDOCX {
}
.Colorful-List-Accent-2-PHPDOCX {
}
.Colorful-List-Accent-3-PHPDOCX {
}
.Colorful-List-Accent-4-PHPDOCX {
}
.Colorful-List-Accent-5-PHPDOCX {
}
.Colorful-List-Accent-6-PHPDOCX {
}
.Colorful-Grid-PHPDOCX {
}
.Colorful-Grid-Accent-1-PHPDOCX {
}
.Colorful-Grid-Accent-2-PHPDOCX {
}
.Colorful-Grid-Accent-3-PHPDOCX {
}
.Colorful-Grid-Accent-4-PHPDOCX {
}
.Colorful-Grid-Accent-5-PHPDOCX {
}
.Colorful-Grid-Accent-6-PHPDOCX {
}
.MyFooter {
  text-align: left;
  page-break-inside: auto;
  page-break-after: auto;
  page-break-before: avoid;
  line-height: 115%;
  margin-top: 0pt;
  margin-bottom: 10pt;
  color: #808080;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
.MyFooterCar {
  color: #808080;
  font-family: Helvetica;
  font-size: 10pt;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  font-variant: normal;
}
</style>
