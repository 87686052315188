<template>
  <v-container class="mt-15 pt-15" fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="4" class="text-h3 font-weight-medium text-center reveal-from-side"
        >S'adapte à tous les sites, à tous les écrans</v-col
      >
    </v-row>
    <v-row justify="center" class="mt-14" no-gutters>
      <v-col cols="5" class="text-center"
        >Notre service a été utilisé dès sa conception dans des concessions automobiles afin d'élaborer un produit le plus adapté
        à votre secteur. Totalement personnalisable, il vous permet de prendre le contrôle total sur vos diffusions.
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-14" no-gutters>
      <v-col cols="12" md="5">
        <v-row justify="center" no-gutters>
          <v-col cols="12" class="text-center">
            <div
              class="text-uppercase d-inline px-2 font-weight-medium border pointer hovered"
              :class="{ selected: device === 'television' }"
              @click="
                () => {
                  device = 'television';
                }
              "
            >
              television
            </div>
            <div
              class="text-uppercase d-inline px-2 font-weight-medium border pointer hovered"
              :class="{ selected: device === 'ipad' }"
              @click="() => (device = 'ipad')"
            >
              ipad
            </div>
            <div
              class="text-uppercase d-inline px-2 font-weight-medium border pointer hovered"
              :class="{ selected: device === 'totem' }"
              @click="() => (device = 'totem')"
            >
              totem
            </div>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="10" md="8" class="mt-15">
                <Television v-show="device === 'television'" :tvHeightSize="{ small: 12, large: 16 }" />
              </v-col>
            </v-row>

            <v-img v-show="device === 'ipad'" :src="require('@/assets/ipad.svg')" contain height="400px"></v-img>

            <v-img v-show="device === 'totem'" :src="require('@/assets/totem_white.svg')" contain height="600px"></v-img>
          </v-col>

          <v-col cols="6" class="text-center pt-15">
            <v-btn block class="gradiant-market-digital-alternative text-uppercase" @click="$vuetify.goTo('#CTA', { duration: 600, easing: 'easeInOutCubic' })">En savoir plus</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="mt-15 mt-md-0">
        <v-row justify="start" class="flex-column flex-wrap" no-gutters>
          <v-row class="mb-8" no-gutters>
            <v-col cols="12" md="5" lg="4" class="d-flex justify-center justify-md-start">
              <v-img :src="require('@/assets/feature2.svg')" contain max-width="150px" max-height="150px"></v-img>
            </v-col>
            <v-col cols="12" md="5">
              <div class="text-h5 font-weight-bold pb-5 text-center text-md-start">Aucune limite</div>
              <div class="pb-6">
                24 heures sur 24, 7 jours sur 7, afficher vos annonces de vente les plus récentes sur tous vos écrans de
                télévision, tablette ou totem.
              </div>
              <div class="text-center text-lg-start">
                <v-btn class="gradiant-market-digital-alternative text-uppercase" @click="$vuetify.goTo('#CTA', { duration: 600, easing: 'easeInOutCubic' })">En savoir plus</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-8 flex-wrap" no-gutters>
            <v-col cols="12" md="5" lg="4" class="d-flex justify-center justify-md-start">
              <v-img :src="require('@/assets/feature1.svg')" contain max-width="150px" max-height="150px"></v-img>
            </v-col>
            <v-col cols="12" md="5">
              <div class="text-h5 font-weight-bold pb-5 text-center text-md-start">Prenez le contrôle</div>
              <div class="pb-6">
                De nombreux paramètres sont disponibles afin de vous permettre de personnaliser au maximum votre écran de
                diffusion.
              </div>
              <div class="text-center text-lg-start">
                <v-btn class="gradiant-market-digital-alternative text-uppercase" @click="$vuetify.goTo('#CTA', { duration: 600, easing: 'easeInOutCubic' })">En savoir plus</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-8 flex-wrap" no-gutters>
            <v-col cols="12" md="5" lg="4" class="d-flex justify-center justify-md-start">
              <v-img :src="require('@/assets/feature3.svg')" contain max-width="150px" max-height="150px"></v-img>
            </v-col>
            <v-col cols="12" md="5">
              <div class="text-h5 font-weight-bold pb-5 text-center text-md-start">Ne changez pas vos habitudes</div>
              <div class="pb-6">
                Nous nous synchronisons avec votre console de publication afin de diffuser vos annonces sur vos écrans.
              </div>
              <div class="text-center text-lg-start">
                <v-btn class="gradiant-market-digital-alternative text-uppercase" @click="$vuetify.goTo('#CTA', { duration: 600, easing: 'easeInOutCubic' })">En savoir plus</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Television from "@/components/Television/Television";

export default {
  name: "CompleteFeatures_1",

  components: {
    Television,
  },

  data() {
    return {
      device: "television",
    };
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 700 !important;
}

.border {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #adadad;
}

.hovered:hover {
  color: #9ab4f7;
  font-weight: 700 !important;
}

.selected {
  color: #6893ff;
  font-weight: 800 !important;
}
</style>
