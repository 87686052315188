<template>
  <v-main class="fill-height pt-0"  style="padding-top: 60px">
    <vue-progress-bar></vue-progress-bar>
    <router-view />
  </v-main>
</template>

<script>
export default {};
</script>
