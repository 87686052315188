import { mapGetters, mapActions } from 'vuex';

const snackBar = {
    methods: {
        ...mapActions(["set_snack"]),

        displayInfoSnack(text) {
            this.set_snack({
                display: true,
                text,
                color: "info"
            });
        },

        displaySuccessSnack(text) {
            this.set_snack({
                display: true,
                text,
                color: "success"
            });
        },

        displayErrorSnack(text) {
            this.set_snack({
                display: true,
                text,
                color: "error"
            });
        }
    },

    computed: {
        ...mapGetters(["get_user", "get_snack"])
    }
}

export default snackBar;