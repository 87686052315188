import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },

    // breakpoint: {
    //     thresholds: {
    //         xs: 0,
    //         sm: 600,
    //         md: 960,
    //         lg: 1280 - 16,
    //         xl: 1280 - 16
    //     }
    // }
});
