<template>
    <ul class="accordion list-reset mb-0">
        <slot />
    </ul>
</template>

<script>
export default {
  name: 'CAccordion'
}
</script>
