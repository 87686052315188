<template>
  <div class="neon-cruip">
    <div class="has-animations is-loaded">
      <!-- <footer class="site-footer center-content-mobile " :class="topOuterDivider && 'has-top-divider'">
        <div class="container">
          <div class="site-footer-inner" :class="topDivider && 'has-top-divider'">
            <div class="footer-top space-between text-xxs">
              <c-logo />
              <c-footer-social />
            </div>
            <div class="footer-bottom space-between text-xxs invert-order-desktop">
              <c-footer-nav />
              <div class="footer-copyright">&copy; 2020 Neon, all rights reserved</div>
            </div>
          </div>
        </div>
      </footer> -->

      <v-container>
        <v-row justify="start" class="top-border py-10 mt-10" no-gutters>
          <div>
            <c-logo />
          </div>
          <div class="mx-10">
            <div class="subtitle-1 font-weight-medium mb-1 text--secondary">Contact</div>
            <div class="caption font-weight-medium text--secondary">06 64 05 41 39</div>
            <div class="caption font-weight-medium text--secondary">contact@market-digital.com</div>
          </div>
          <div class="d-flex flex-grow-1 align-center text-right justify-end">
            <c-footer-nav />
          </div>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import CLogo from "@/components/cruip_template/layout/partials/Logo.vue";
import CFooterNav from "@/components/cruip_template/layout/partials/FooterNav.vue";
import CFooterSocial from "@/components/cruip_template/layout/partials/FooterSocial.vue";

export default {
  name: "CFooter",
  components: {
    CLogo,
    CFooterNav,
    CFooterSocial,
  },
  props: {
    topOuterDivider: {
      type: Boolean,
      default: false,
    },
    topDivider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.top-border {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #adadad48;
}
</style>
