<template>
  <div>
    <!-- <v-row class="flex-column flex-sm-row text-center text-md-left" justify="center" justify-md="space-between" align="center">
    <v-col cols="12" md="6" class="mt-10 mt-md-0" style="z-index: 99" @mousemove="mouseover"> -->
    <div
      class="monitor-container"
      @click="switchTvMode"
      :style="{ width: $vuetify.breakpoint.lgAndUp ? '100%' : '60%' }"
      @mousemove="mouseover"
    >
      <div class="monitor">
        <div class="monitor-screen">
          <div :class="{ powerOn: tvMode, powerOff: !tvMode }">
            <div
              class="monitor-content"
              :style="{
                'max-height': $vuetify.breakpoint.lgAndUp ? tvHeightSize.large + 'em' : tvHeightSize.small + 'em',
              }"
            >
              <div class="overlay">AV-1</div>

              <div class="screen" style="z-index: 1000">
                <div class="hide-carousel">
                  <div></div>
                </div>
                <v-carousel
                  v-model="carouselIndex"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false"
                  continuous
                  style="padding:0; margin: 0"
                >
                  <v-carousel-item>
                    <v-img
                      :src="require('@/assets/logo/stamp white.svg')"
                      contain
                      :height="$vuetify.breakpoint.lgAndUp ? tvHeightSize.large + 'em' : tvHeightSize.small + 'em'"
                    ></v-img>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img
                      :src="require('@/assets/images/1081569_01_hd.jpg')"
                      contain
                      :height="$vuetify.breakpoint.lgAndUp ? tvHeightSize.large + 'em' : tvHeightSize.small + 'em'"
                    ></v-img>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img
                      :src="require('@/assets/images/1081569_011_hd.jpg')"
                      contain
                      :height="$vuetify.breakpoint.lgAndUp ? tvHeightSize.large + 'em' : tvHeightSize.small + 'em'"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center caption">Allumer la télévision en cliquant dessus !</div>
    <div class="remote" :style="{ left: remotePos.x + 'px', top: remotePos.y + 'px' }" v-if="remote">
      <div class="remote__case">
        <div class="remote__power" :class="{ on: tvMode }">
          <v-icon :color="tvMode ? 'green lighten-3' : 'white'">mdi-power</v-icon>
        </div>
      </div>
    </div>
  </div>
  <!-- </v-col>
  </v-row> -->
</template>

<script>
export default {
  name: "Television",

  props: {
    remote: {
      type: Boolean,
      default: false,
    },
    tvHeightSize: {
      type: Object,
      default: () => ({ small: 14, large: 16 }),
    },
  },
  data() {
    return {
      tvMode: false,
      //   { small: 14, large: 16 }

      remotePos: { x: 0, y: 0 },

      carouselIndex: 0,

      interval: null,
    };
  },

  mounted() {
    try {
      const { x, y, height } = document.getElementsByClassName("monitor-content")[0].getBoundingClientRect();

      this.remotePos.x = x;
      this.remotePos.y = y + height;
    } catch (error) {}
  },

  beforeDestroy() {
    this.clearInterval();
  },

  methods: {
    mouseover(e) {
      this.remotePos.x = e.pageX;
      this.remotePos.y = e.pageY;
    },

    incrementCarousel() {
      if (this.carouselIndex + 1 > 2) this.carouselIndex = 0;
      else this.carouselIndex++;
    },

    switchTvMode() {
      this.tvMode = !this.tvMode;
      this.carouselIndex = 0;

      if (this.tvMode) this.interval = setInterval(this.incrementCarousel, 8000);
      else if (!this.tvMode) this.clearInterval();
    },

    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$screen-background: #121010;

.monitor-container {
  cursor: none;
  margin: auto;
}

.monitor {
  background: #000;
  position: relative;
  border-top: 3px solid #888;
  margin: 1%;
  padding: 2% 2% 4% 2%;
  border-radius: 10px;
  border-bottom-left-radius: 50% 2%;
  border-bottom-right-radius: 50% 2%;
  transition: margin-right 1s;
  height: 100%;
}

.monitor:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 3%;
  left: 36%;
  height: 0.5%;
  width: 28%;
  background: #ddd;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 white;
}

.monitor-screen {
  height: auto;
}

.monitor-content {
  background: $screen-background;
  max-width: 100%;
  margin-top: 0px;
  overflow: hidden;
  border: 2px solid #666;
  display: block;

  // flicker
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparentize($screen-background, 0.94);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
  }
  // scanlines
  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(transparentize($screen-background, 1) 50%, transparentize(darken($screen-background, 10), 1) 50%),
      linear-gradient(90deg, transparentize(#ff0000, 0.94), transparentize(#00ff00, 0.98), transparentize(#0000ff, 0.94));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
}

.powerOn .monitor-content::after {
  animation: flicker 0.15s infinite;
}

.screen {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  border: none;
  z-index: 9;
}

.hide-carousel {
  background-color: black;
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 6000;
}

.powerOn .monitor-content .screen .hide-carousel {
  -webkit-animation: television 3s forwards;
  -moz-animation: television 3s forwards;
  -o-animation: television 3s forwards;
  animation: television 3s forwards;
  -webkit-animation-name: television;
  animation-name: television;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

.overlay {
  color: #00ff00;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  text-shadow: 2px 2px black, -2px -2px black, -2px 2px black, 2px -2px black;
  visibility: hidden;
  pointer-events: none;
  z-index: 10000;
}

.powerOn .monitor-content .overlay {
  animation: overlay-anim 5s linear;
  animation-fill-mode: forwards;
}

.remote {
  position: absolute;
  bottom: 5%;
  left: 50%;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  z-index: 10000;
}

.remote__case {
  width: 50px;
  height: 100px;
  background: rgb(17, 109, 184);
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(13, 68, 114);
  text-align: center;
}

.remote__power {
  background: brown;
  display: inline-block;
  border-radius: 5px;
  padding: 1px;
  margin-top: 10px;
}
@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }
  5% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes turn-on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  100% {
    animation-timing-function: $ease-in-quint;
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

@keyframes flicker {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      opacity: random();
    }
  }
}

@media all and (min-width: 960px) {
  .powerOn {
    -webkit-animation: tvflicker 0.2s infinite alternate;
    -moz-animation: tvflicker 0.5s infinite alternate;
    -o-animation: tvflicker 0.5s infinite alternate;
    animation: tvflicker 0.5s infinite alternate;
  }

  @-webkit-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(200, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 95px 0 rgba(200, 230, 255, 0.45);
    }
  }
  @-moz-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @-o-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
}

@-webkit-keyframes television {
  0% {
    background: black;
  }
  1% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  20% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  49% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  100% {
    background: transparent;
  }
}
@keyframes television {
  0% {
    background: black;
  }
  1% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  20% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  49% {
    background-image: url("https://media.giphy.com/media/xaMg6NGwH2fFS/giphy.gif");
  }
  100% {
    background: transparent;
  }
}

@-webkit-keyframes hide-carousel {
  0% {
    background: black;
  }
  100% {
    background: transparent;
  }
}
@keyframes hide-carousel {
  0% {
    background: black;
  }
  100% {
    background: transparent;
  }
}
</style>
