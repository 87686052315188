<template>
  <v-container class="fill-height">
    <vue-element-loading :active="true" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0)'">
      <looping-rhombuses-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
    </vue-element-loading>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  name: "Logout",

  components: { LoopingRhombusesSpinner },

  data() {
    return {};
  },
  mounted() {
    this.logout();
  },
  methods: {
    ...mapActions(["set_user"]),

    async logout() {
      try {
        await this.$axios.post("/logout");
      } catch (err) {
        alert("Couldn't logout", err);
      } finally {
        this.set_user({});
        // this.$cookies.remove("connect.sid");
        if (this.$route.path !== "/")
          this.$router.push("/").catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
