const env = {
    development: {
        axios: {
            baseURL: "http://localhost:3000/api"
        }
    }, production: {
        axios: {
            baseURL: "https://www.market-digital.com/api"
        }
    }, staging: {
        axios: {
            baseURL: "https://www.staging.market-digital.com/api"
        }
    }
}
export default env;