"use strict";

import Vue from 'vue';
import axios from "axios";
import app from '../main'; // import the instance
import noProgressRoutes from '@/utils/noProgressRoutes';
import store from '@/store';
import router from '@/router';
import env from '@/utils/env';

const UNAUTHORIZED = 401;

// Full config:  https://github.com/axios/axios#request-config

let config = {
  baseURL: env[process.env.NODE_ENV || "development"]?.axios?.baseURL,
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    if (!config.url.slice(0, config.baseURL.length).startsWith(noProgressRoutes[0]) &&
      !config.url.slice(0, config.baseURL.length).startsWith(noProgressRoutes[1]))
      app.$Progress.start(); // for every request start the progress
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    app.$Progress.fail();
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    app.$Progress.finish(); // finish when a response is received

    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    app.$Progress.fail();
    // console.log("ici", error.config.url);
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      store.dispatch('set_snack', { color: "error", text: `Vous n'avez pas été autoriser à accéder à la ressource '${error.config.url}', vous avez été donc déconnecté. `, display: true });
      router.push('/logout');
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
