var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",class:[
        _vm.topOuterDivider && 'has-top-divider',
        _vm.bottomOuterDivider && 'has-bottom-divider',
        _vm.hasBgColor && 'has-bg-color',
        _vm.invertColor && 'invert-color'
    ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-inner",class:[
                _vm.topDivider && 'has-top-divider',
                _vm.bottomDivider && 'has-bottom-divider'
            ]},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }