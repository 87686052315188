import { mapGetters, mapActions } from "vuex"

const user = {
    methods: {
        ...mapActions(["set_user"]),

        isRole(role) {
            role;
            return this.get_user?.roles?.includes(role);
        }
    },

    computed: {
        ...mapGetters(["get_user"]),

        isAdmin() {
            this.get_user.roles;

            return this.get_user.roles ? this.get_user.roles.includes("admin") : false;
        },
        isLogin() {
            this.get_user.username;

            return this.get_user.username && this.get_user.username.length > 0;
        }
    },
}

export default user;