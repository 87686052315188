<template>
  <!--  style="padding-top: 60px" est necessaire si on retire pas "absolute" de l'appbar -->
  <main class="site-content fill-height" style="padding-top: 60px">
    <v-main class="fill-height pt-0">
      <vue-progress-bar></vue-progress-bar>
      <router-view />
    </v-main>
  </main>
</template>

<script>
import CHeader from "@/components/cruip_template/layout/Header.vue";

export default {
  components: {
    CHeader,
  },
};
</script>
