<template>
  <nav class="footer-nav">
    <ul class="list-reset">
      <li>
        <router-link to="/contact/">Nous contacter</router-link>
      </li>
      <li>
        <router-link to="/about-us/">A propos de nous</router-link>
      </li>
      <li>
        <router-link to="/faqs/">FAQ</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "CFooterNav",
};
</script>
