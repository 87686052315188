<template>
  <c-generic-section top-divider>
    <div class="container-xs">
      <c-section-header :data="genericSection04Header" class="center-content" />
      <c-accordion>
        <c-accordion-item title="Nisi porta lorem mollis aliquam ut.">
          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts
          and visual mockups.
        </c-accordion-item>
        <c-accordion-item title="Nisi porta lorem mollis aliquam ut.">
          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts
          and visual mockups.
        </c-accordion-item>
        <c-accordion-item title="Nisi porta lorem mollis aliquam ut.">
          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts
          and visual mockups.
        </c-accordion-item>
        <c-accordion-item title="Nisi porta lorem mollis aliquam ut.">
          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts
          and visual mockups.
        </c-accordion-item>
        <c-accordion-item title="Nisi porta lorem mollis aliquam ut.">
          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts
          and visual mockups.
        </c-accordion-item>
      </c-accordion>
    </div>
  </c-generic-section>
</template>

<script>
import CGenericSection from "@/components/cruip_template/sections/GenericSection.vue";
import CSectionHeader from "@/components/cruip_template/sections/partials/SectionHeader.vue";
import CAccordion from "@/components/cruip_template/elements/Accordion.vue";
import CAccordionItem from "@/components/cruip_template/elements/AccordionItem.vue";

export default {
  data() {
    return {
      genericSection04Header: {
        title: "FAQ - Réponses aux questions les plus souvent posées",
      },
    };
  },
  components: {
    CGenericSection,
    CSectionHeader,
    CAccordion,
    CAccordionItem,
  },
};
</script>

<style lang="scss" scoped></style>
