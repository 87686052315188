import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import * as gettersUser from './user/gettersUser';
import * as actionsUser from './user/actionsUser';
import * as mutationsUser from './user/mutationsUser';

import * as gettersSnack from './snack/gettersSnack';
import * as actionsSnacks from './snack/actionsSnack';
import * as mutationsSnack from './snack/mutationsSnack';

Vue.use(Vuex)

const moduleUser = {
  state: {
    user: {}
  },
  actions: actionsUser,
  mutations: mutationsUser,
  getters: gettersUser

}

const moduleSnack = {
  state: {
    snack: {
      display: false,
      text: "",
      color: ""
    }
  },
  actions: actionsSnacks,
  mutations: mutationsSnack,
  getters: gettersSnack
}

export default new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    moduleUser,
    moduleSnack
  },
  plugins: [createPersistedState()]
})
