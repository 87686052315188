var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pricing section",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color',
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pricing-inner section-inner",class:[_vm.topDivider && 'has-top-divider', _vm.bottomDivider && 'has-bottom-divider']},[_c('c-section-header',{staticClass:"center-content reveal-from-bottom mb-1",attrs:{"data":_vm.sectionHeader,"tag":"h1"}}),_vm._m(0)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mb-12 pricing-item-features-list",staticStyle:{"align-self":"center"}},[_c('li',{staticClass:"is-checked fixtur reveal-from-left"},[_vm._v("Vos VO")]),_c('li',{staticClass:"is-checked fixtur reveal-from-right"},[_vm._v("Vos VN")]),_c('li',{staticClass:"is-checked fixtur reveal-from-left"},[_vm._v("Vos VD")]),_c('li',{staticClass:"is-checked fixtur reveal-from-right"},[_vm._v("Vidéos constructeur")]),_c('li',{staticClass:"is-checked fixtur reveal-from-left"},[_vm._v("Vidéos du groupe")]),_c('li',{staticClass:"is-checked fixtur reveal-from-right"},[_vm._v("Vidéos du site")]),_c('li',{staticClass:"is-checked fixtur reveal-from-left"},[_vm._v("Opération service après vente")]),_c('li',{staticClass:"is-checked fixtur reveal-from-right"},[_vm._v("Info traffic en temps réel")]),_c('li',{staticClass:"is-checked fixtur reveal-from-left"},[_vm._v("Météo nationale, régionale et locale")]),_c('li',{staticClass:"is-checked fixtur reveal-from-right"},[_vm._v(" Les dernières informations sportives et économiques nationale et locale ")])])
}]

export { render, staticRenderFns }